import React, { useEffect, useRef, useState } from "react";
import HeaderN from "../component/HeaderN";
import DbFooter from "../component/DbFooter";
import { Container, Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import follower from "../assets/images/follower.svg";
import hash from "../assets/images/hash.svg";
import Receipt from "../assets/images/Receipt.svg";
import accessCenter from "../assets/images/accessCenter.png";
import office from "../assets/images/office.svg";
import chair from "../assets/images/chair.svg";
import location from "../assets/images/location.svg";
import call from "../assets/images/call.svg";
import website from "../assets/images/sortIcons/political.svg";
import addPic from "../assets/images/add-square.svg";
import user from "../assets/images/user.svg";
import mail from "../assets/images/mail.svg";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Autocomplete from "react-google-autocomplete";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import {
  Checkbox,
  FormControlLabel,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { debounce } from "lodash";

import { Link } from "react-router-dom";
import { Get, Post } from "../services/user.services";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const localAdmin = JSON.parse(localStorage.getItem("AdminPopup"));
  const localOffice = JSON.parse(localStorage.getItem("OfficeDetails"));
  const navigate = useNavigate();
  const [officetypes, setOfficeTypes] = useState([]);
  const [departmentTypes, setDepartmentTypes] = useState([]);
  const [officeNames, setOfficeNames] = useState([]);
  const [designations, setDesignation] = useState([]);
  const [onboard, setOnboard] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [show, setShow] = useState(false);
  const [office1, setOffice1] = useState(false);
  const [office2, setOffice2] = useState(false);
  const [office3, setOffice3] = useState(false);
  const [office4, setOffice4] = useState(false);
  const [office5, setOffice5] = useState(false);
  const [office6, setOffice6] = useState(false);

  const [AdminDetails, setAdminDetails] = useState({
    company_details: {
      company_name: "",
      company_number: "",
      company_vat: "",
      profile_image: "",
    },
    office_details: {
      name: "",
      complete_address: "",
      latitude: "",
      longitude: "",
      phone: "",
      country_code: "",
      city: "",
      country: "",
      post_code: "",
      website: "",
      office_type: "option1",
    },
    administrator_details: {
      full_name: localAdmin?.first_name + " " + localAdmin?.last_name,
      first_name: localAdmin?.first_name,
      last_name: localAdmin?.last_name,
      office_name: "",
      designation_id: localAdmin?.designation_id,
      department: "",
      admin_profile: "",
      country_code: "",
      phone: "",
      office_email: "",
    },
    admin_rights: {
      allowed_to_onboard_users: true,
      allowed_to_deregister_users: true,
      allowed_to_assign_users_rights: true,
      allowed_to_set_financial_limit: true,
      allowed_complete_access: true,
      allowed_to_broadcast_tasks: true,
      allowed_to_purchase_content: false,
      price_range: {
        minimum_price: "",
        maximum_price: "",
      },
    },
  });

  const [addOffice, setAddOffice] = useState({
    company_name: "",
    company_number: "",
    company_vat: "",
    profile_image: "",
    name: "",
    office_type_id: "option1",
    address: {
      country: "",
      city: "",
      complete_address: "",
      Pin_Location: { lat: "", long: "" },
      location: { type: "Point", coordinates: ["", ""] },
    },
    country_code: "",
    phone: "",
    website: "",
    is_another_office_exist: false,
  });

  const AddOfficeDetails = (e) => {
    if(e.target.name === "phone" && e.target.value.length <= 15){
      setAddOffice((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
    else if(e.target.name != "phone"){
      setAddOffice((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const checkEmail = debounce(async (email) => {
    try {
      const obj = {
        email: email,
      };
      const resp = await Post(`mediaHouse/checkEmailAvailability`, obj);
      if (resp) {
        setEmailExist(false);
      }
    } catch (error) {
      setEmailExist(true);
    }
  }, 300);

  const AddOffice = async (e) => {
    e.preventDefault();
    try {
      if (addOffice.office_type_id === "option1") {
        toast.error("Select Office Type", { hideProgressBar: true });
      } else if (addOffice.address.complete_address === "") {
        toast.error("Fill Office Address", { hideProgressBar: true });
      } else if (addOffice.country_code === "") {
        toast.error("Select Country Code", { hideProgressBar: true });
      }
      // if (addOffice.company_number.length !== 9) {
      //   toast.error("Company number should be exactly 9 digits.", { hideProgressBar: true });
      // } if (addOffice.company_vat.length !== 8) {
      //   toast.error("Company Vat should be exactly 8 digits.", { hideProgressBar: true });
      // }
      else {
        const resp = await Post("mediaHouse/addOfficeDetail", addOffice);
        if (resp) {
          setOnboard(true);
          setShow(true);
          localStorage.setItem("OfficeDetails", JSON.stringify(addOffice));
          if (localStorage.getItem("OfficeDetails")) {
            getOfficeDetails(resp.data.Create_Office_Detail.company_vat);
          }
        }
      }
    } catch (error) {
      console.log(error, ",--------error");
    }
  };

  const handleCompanyChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAdminDetails((prev) => ({
      ...prev,
      company_details: { ...prev.company_details, [name]: value },
    }));
  };

  console.log("AdminDetails------->", AdminDetails);

  const handleOfficeChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    if(name == "phone" && value.length <= 15){
      setAdminDetails((prev) => ({
        ...prev,
        office_details: { ...prev.office_details, [name]: value },
      }));
    }
    else if(name != "phone"){
      setAdminDetails((prev) => ({
        ...prev,
        office_details: { ...prev.office_details, [name]: value },
      }));
    }
  };

  const handleAdministratorChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setAdminDetails((prev) => ({
      ...prev,
      administrator_details: { ...prev.administrator_details, [name]: value },
    }));
  };

  const handleAdminRights = (event) => {
    const name = event.target.name;
    const value = event.target.checked;
    setAdminDetails((prev) => ({
      ...prev,
      admin_rights: { ...prev.admin_rights, [name]: value },
    }));
  };

  const handleAddress = (place) => {
    let city = "";
    const addressComponents = place.address_components;
    if (addressComponents) {
      const cityComponent = addressComponents?.find(
        (component) =>
          component.types.includes("locality") ||
          component.types.includes("administrative_area_level_1") ||
          component.types.includes("administrative_area_level_2")
      );

      if (cityComponent) {
        city = cityComponent.long_name;
      }
    }

    if (!city && place.formatted_address) {
      city = place.formatted_address.split(",")[0];
    }

    const country =
      place.address_components.find((component) =>
        component.types.includes("country")
      )?.long_name || "";

    setAdminDetails((prev) => ({
      ...prev,
      office_details: {
        ...prev.office_details,
        address: place.formatted_address,
        city: city,
        country: country,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      },
    }));
    setAddOffice((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        // pincode: ({ ...prev.address.pincode }),
        complete_address: place.formatted_address,
        city: city,
        country: country,
        Pin_Location: {
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        },
        location: {
          type: "Point",
          coordinates: [
            place.geometry.location.lat(),
            place.geometry.location.lng(),
          ],
        },
      },
    }));
  };

  const AddPinCode = (e) => {
    setAddOffice((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        pincode: e.target.value,
      },
    }));
  };

  const getOfficeType = async () => {
    const list = await Get("mediaHouse/getOfficeType");
    setOfficeTypes(list.data.data);
  };

  const getDepartmentType = async () => {
    const list = await Get("mediaHouse/getDepartmentType");
    setDepartmentTypes(list.data.data);
  };

  const getOfficeDetails = async (vat) => {
    if (localStorage.getItem("OfficeDetails")) {
      const list = await Get(
        `mediaHouse/getOfficeDetail?company_vat=${
          vat ? vat : localOffice?.company_vat
        }`
      );
      if (list) {
        setOfficeNames(list.data.data);
      }
    }
  };

  const NextPage = (e) => {
    e.preventDefault();
    if (officeNames.length === 0) {
      toast.error("Add Office Details", { hideProgressBar: true });
    } else if (AdminDetails.administrator_details.office_name === "") {
      toast.error("Select Office Name", { hideProgressBar: true });
    } else if (AdminDetails.administrator_details.department === "") {
      toast.error("Select Department", { hideProgressBar: true });
    } else if (AdminDetails.administrator_details.country_code === "") {
      toast.error("Select Country Code", { hideProgressBar: true });
    } else if (emailExist === true) {
      // toast.error("Select Country Code")
    } else {
      localStorage.setItem("Page1", JSON.stringify(AdminDetails));
      if (localStorage.getItem("Page1")) {
        navigate("/upload-docs");
      }
    }
  };

  const AddCompanyLogo = async (file) => {
    const Formdata = new FormData();
    Formdata.append("path", "user");
    Formdata.append("media", file);
    const filepath = await Post("mediaHouse/uploadUserMedia", Formdata);
    setAdminDetails((prev) => ({
      ...prev,
      company_details: {
        ...prev.company_details,
        profile_image: filepath.data.path,
      },
    }));
  };

  const AddAdminProfile = async (file) => {
    const Formdata = new FormData();

    Formdata.append("path", "user");
    Formdata.append("media", file);

    const filepath = await Post("mediaHouse/uploadUserMedia", Formdata);
    setAdminDetails((prev) => ({
      ...prev,
      administrator_details: {
        ...prev.administrator_details,
        admin_profile: filepath.data.path,
      },
    }));
  };

  const getDesignation = async () => {
    const list = await Get(`mediaHouse/getCategoryType?type=designation`);
    setDesignation(list.data.data);
  };

  const filteredDesignation = designations.filter(
    (item) => item._id === localAdmin?.designation_id
  );

  useEffect(() => {
    getOfficeType();
    getDepartmentType();
    getDesignation();
  }, []);

  // Address Handle-

  const searchBoxRefStreet = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const onMapLoadStreet = (map) => {
    const searchBox = new window.google.maps.places.SearchBox(
      searchBoxRefStreet.current
    );
    // console.log('harshit', window.google.maps.places)
    searchBox.addListener("places_changed", () => {
      const places = searchBox.getPlaces();
      if (places.length === 0) {
        return;
      }

      const htmlString = places[0].adr_address;
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, "text/html");

      // Extract country name and region
      const countryNameElement = doc.querySelector(".country-name");
      const cityElement = doc.querySelector(".locality");

      if (countryNameElement) {
        const address = places[0].formatted_address;
        const country = countryNameElement?.textContent;
        const city = cityElement?.textContent;
        const latitude = places[0].geometry.location.lat();
        const longitude = places[0].geometry.location.lng();

        setAdminDetails((prev) => ({
          ...prev,
          office_details: {
            ...prev.office_details,
            address,
            city,
            country,
            latitude,
            longitude,
          },
        }));

        setAddOffice((prev) => ({
          ...prev,
          address: {
            ...prev.address,
            complete_address: address,
            city,
            country,
            Pin_Location: {
              lat: latitude,
              long: longitude,
            },
            location: {
              type: "Point",
              coordinates: [latitude, longitude],
            },
          },
        }));
      }
    });

    // setAdminDetails((prev) => ({
    //   ...prev,
    //   office_details: {
    //     ...prev.office_details,
    //     address: place.formatted_address,
    //     city: city,
    //     country: country,
    //     latitude: place.geometry.location.lat(),
    //     longitude: place.geometry.location.lng(),
    //   },
    // }));
    // setAddOffice((prev) => ({
    //   ...prev,
    //   address: {
    //     ...prev.address,
    //     // pincode: ({ ...prev.address.pincode }),
    //     complete_address: place.formatted_address,
    //     city: city,
    //     country: country,
    //     Pin_Location: {
    //       lat: place.geometry.location.lat(),
    //       long: place.geometry.location.lng(),
    //     },
    //     location: {
    //       type: "Point",
    //       coordinates: [
    //         place.geometry.location.lat(),
    //         place.geometry.location.lng(),
    //       ],
    //     },
    //   },
    // }));
  };

  return (
    <>
      <HeaderN />
      <div className="page-wrap login-page sign p-0">
        <Container fluid className="pdng">
          <div className="log-wrap">
            <Row className="row-w-m m-0 position-relative">
              <Col lg={6} md={6} sm={12} xs={12} className="p-0 lft_colm">
                <img
                  src={accessCenter}
                  alt=""
                  className="resp_bg obj_fit_contain"
                />
                <div className="login_stepsWrap left-pdng bg-white">
                  {/* {currentStep === 1 && ( */}
                  <div className="onboardMain">
                    <div className="onboardIntro">
                      <div className="pg_heading">
                        <h1 className="mb-0">Onboard now</h1>
                      </div>
                      <div className="onboardStep b_border">
                        <p className="mb-0 brd_btm_sign">
                          Join our growing tribe, and connect directly with the
                          people. Please add your company, offices, and employee
                          details to register
                        </p>
                      </div>
                    </div>
                    <div className="onboardStep">
                      <form
                        onSubmit={(e) => {
                          AddOffice(e);
                          e.preventDefault();
                        }}
                      >
                        <div className="companyDetails sign_section">
                          <p className="onbrdheading sign_hdng">
                            Company Details
                          </p>
                          <Row className="rw_gp_sml">
                            <Col lg={9} md={9} sm={12}>
                              <Row className="comp_frm_gap">
                                <Col md={12} sm={12} xs={12} className="">
                                  <Form.Group className="form-group">
                                    <img src={follower} alt="company" />
                                    <Form.Control
                                      type="text"
                                      className="rnd grey"
                                      // disabled={onboard}
                                      name="company_name"
                                      required
                                      onChange={(e) => {
                                        handleCompanyChange(e);
                                        AddOfficeDetails(e);
                                      }}
                                      placeholder="Company name *"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12}>
                                  <Form.Group className="form-group">
                                    <img src={hash} alt="company" />
                                    <Form.Control
                                      type="text"
                                      className="rnd grey"
                                      // disabled={onboard}
                                      name="company_number"
                                      maxLength={8}
                                      required
                                      onChange={(e) => {
                                        const inputValue = e.target.value;

                                        if (inputValue) {
                                          handleCompanyChange(e);
                                          AddOfficeDetails(e);
                                        }
                                      }}
                                      placeholder="Company number *"
                                    />
                                    {AdminDetails.company_details.company_number
                                      .length < 8 && (
                                      <span className="errorInput">
                                        Please enter 8 digits
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col lg={6} md={12} sm={12} xs={12}>
                                  <Form.Group className="form-group">
                                    <img src={Receipt} alt="company" />
                                    <Form.Control
                                      type="text"
                                      className="rnd grey"
                                      // disabled={onboard}
                                      name="company_vat"
                                      required
                                      maxLength={9}
                                      onChange={(e) => {
                                        handleCompanyChange(e);
                                        AddOfficeDetails(e);
                                      }}
                                      placeholder="Company VAT *"
                                    />
                                    {AdminDetails?.company_details?.company_vat
                                      ?.length < 9 && (
                                      <span className="errorInput">
                                        Please enter 9 digits
                                      </span>
                                    )}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={3} md={3} sm={12}>
                              <div className="currentPic logo_inp position-relative text-center">
                                {AdminDetails.company_details.profile_image ===
                                  "" && <img src={addPic} alt="" />}
                                {AdminDetails.company_details.profile_image !==
                                  "" && (
                                  <img
                                    className="uploaded"
                                    src={
                                      AdminDetails.company_details.profile_image
                                    }
                                    alt=""
                                  />
                                )}
                                {AdminDetails.company_details.profile_image ===
                                  "" && (
                                  <span className="mt-2 d-block">
                                    Add company logo
                                  </span>
                                )}

                                <input
                                  type="file"
                                  // disabled={onboard}
                                  required
                                  onChange={(e) => {
                                    AddCompanyLogo(e.target.files[0]);
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="officeDetails sign_section">
                          <p className="onbrdheading sign_hdng">
                            Office details
                          </p>
                          <Row>
                            <Col md={6}>
                              <Form.Group className="mb-4 form-group">
                                <img src={office} alt="" />
                                <Form.Control
                                  type="text"
                                  className=""
                                  placeholder="Enter office name *"
                                  name="name"
                                  required
                                  onChange={(e) => {
                                    handleOfficeChange(e);
                                    AddOfficeDetails(e);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-4 form-group">
                                <img src={chair} alt="" />
                                <Select
                                  className="w-100 slct_sign"
                                  value={
                                    addOffice.office_type_id
                                      ? addOffice.office_type_id
                                      : "option1"
                                  }
                                  name="office_type_id"
                                  onChange={(e) => {
                                    handleOfficeChange(e);
                                    AddOfficeDetails(e);
                                  }}
                                >
                                  <MenuItem
                                    // disabled
                                    className="selectPlaceholder"
                                    value="option1"
                                  >
                                    Select Office Type{" "}
                                  </MenuItem>
                                  {officetypes &&
                                    officetypes.map((value, index) => {
                                      return (
                                        <MenuItem value={value._id}>
                                          {value.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </Form.Group>
                            </Col>
                            <Col md={6} className="">
                              <Form.Group className="mb-4 form-group">
                                <img src={location} alt="" />
                                <Form.Control
                                  type="text"
                                  className=""
                                  placeholder="Apartment number/ house name *"
                                  name="pincode"
                                  onChange={(e) => {
                                    handleOfficeChange(e);
                                    AddPinCode(e);
                                  }}
                                  required
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-4 form-group">
                                <img src={location} alt="" />
                                <Form.Control
                                  placeholder="Post code"
                                  name="school_address"
                                  className="addr_custom_inp w-100"
                                  type="textarea"
                                  // onChange={(e) => { handleChange(e) }}
                                  onFocus={handlePopupOpen}
                                  onClick={handlePopupOpen}
                                  ref={searchBoxRefStreet}
                                />

                                {showPopup && (
                                  <div className="map-popup">
                                    <GoogleMap
                                      onLoad={onMapLoadStreet}
                                    ></GoogleMap>
                                  </div>
                                )}
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-4 form-group">
                                <img src={location} alt="" />
                                <Form.Control
                                  type="text"
                                  className=""
                                  placeholder="City"
                                  name="city"
                                  value={AdminDetails.office_details.city}
                                  onChange={(e) => {
                                    setAdminDetails((prev) => ({
                                      ...prev,
                                      office_details: {
                                        ...prev.office_details,
                                        city: e.target.value,
                                      },
                                    }));
                                    setAddOffice((prev) => ({
                                      ...prev,
                                      address: {
                                        ...prev.address,
                                        // pincode: ({ ...prev.address.pincode }),

                                        city: e.target.value,
                                      },
                                    }));
                                  }}
                                  // disabled
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-4 form-group">
                                <img src={location} alt="" />
                                <Form.Control
                                  type="text"
                                  className=""
                                  placeholder="Country"
                                  name="country"
                                  value={AdminDetails.office_details.country}
                                  // disabled
                                  onChange={(e) => {
                                    setAdminDetails((prev) => ({
                                      ...prev,
                                      office_details: {
                                        ...prev.office_details,

                                        country: e.target.value,
                                      },
                                    }));

                                    setAddOffice((prev) => ({
                                      ...prev,
                                      address: {
                                        ...prev.address,

                                        country: e.target.value,
                                      },
                                    }));
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={6}>
                              <div className="number_inp_wrap">
                                {/* Phone start */}
                                <input
                                  type="number"
                                  className="input_nmbr"
                                  name="phone"
                                  placeholder="Phone"
                                  required
                                  value={addOffice.phone}
                                  onChange={(e) => {
                                    handleOfficeChange(e);
                                    AddOfficeDetails(e);
                                  }}
                                  maxLength={15}
                                />
                                <PhoneInput
                                  className="f_1 cntry_code"
                                  international
                                  required
                                  countryCallingCodeEditable={true}
                                  name="country_code"
                                  onChange={(e) => {
                                    setAdminDetails((prev) => ({
                                      ...prev,
                                      office_details: {
                                        ...prev.office_details,
                                        country_code: e,
                                      },
                                    }));
                                    setAddOffice((prev) => ({
                                      ...prev,
                                      country_code: e,
                                    }));
                                  }}
                                />
                              </div>
                              {/* <h6 className='text-pink cursor-pointer'>Add another phone number</h6> */}
                            </Col>
                            <Col md={6}>
                              <Form.Group className="mb-4 form-group">
                                <img src={website} alt="" />
                                <Form.Control
                                  type="text"
                                  className=""
                                  placeholder="Website"
                                  name="website"
                                  required
                                  onChange={(e) => {
                                    handleOfficeChange(e);
                                    AddOfficeDetails(e);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          {onboard && (
                            <FormControlLabel
                              className="anthr_office_check"
                              control={<Checkbox />}
                              label="Onboard another office"
                              onChange={(e) => setOffice1(e.target.checked)}
                            />
                          )}

                          {office1 && (
                            <>
                              <Row className="mt-2">
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={office} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Enter office name *"
                                      name="name"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={chair} alt="" />
                                    <Select
                                      className="w-100 slct_sign"
                                      name="office_type_id"
                                    >
                                      <MenuItem
                                        // disabled
                                        className="selectPlaceholder"
                                        value="option1"
                                      >
                                        Select Office Type{" "}
                                      </MenuItem>
                                      {officetypes &&
                                        officetypes.map((value, index) => {
                                          return (
                                            <MenuItem value={value._id}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6} className="">
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Apartment number/ house name *"
                                      name="pincode"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      placeholder="Post code"
                                      className="addr_custom_inp w-100"
                                      type="textarea"
                                      // onChange={(e) => { handleChange(e) }}
                                      onFocus={handlePopupOpen}
                                      onClick={handlePopupOpen}
                                      ref={searchBoxRefStreet}
                                    />

                                    {showPopup && (
                                      <div className="map-popup">
                                        <GoogleMap
                                          onLoad={onMapLoadStreet}
                                        ></GoogleMap>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="City"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Country"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <div className="number_inp_wrap">
                                    {/* Phone start */}
                                    <input
                                      type="number"
                                      className="input_nmbr"
                                      placeholder="Phone"
                                    />
                                    <PhoneInput
                                      className="f_1 cntry_code"
                                      international
                                      required
                                      countryCallingCodeEditable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={website} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Website"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <FormControlLabel
                                className="anthr_office_check"
                                control={<Checkbox />}
                                label="Onboard another office"
                                onChange={(e) => setOffice2(e.target.checked)}
                              />
                            </>
                          )}

                          {office2 && (
                            <>
                              <Row className="mt-2">
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={office} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Enter office name *"
                                      name="name"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={chair} alt="" />
                                    <Select
                                      className="w-100 slct_sign"
                                      name="office_type_id"
                                    >
                                      <MenuItem
                                        // disabled
                                        className="selectPlaceholder"
                                        value="option1"
                                      >
                                        Select Office Type{" "}
                                      </MenuItem>
                                      {officetypes &&
                                        officetypes.map((value, index) => {
                                          return (
                                            <MenuItem value={value._id}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6} className="">
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Apartment number/ house name *"
                                      name="pincode"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      placeholder="Post code"
                                      className="addr_custom_inp w-100"
                                      type="textarea"
                                      // onChange={(e) => { handleChange(e) }}
                                      onFocus={handlePopupOpen}
                                      onClick={handlePopupOpen}
                                      ref={searchBoxRefStreet}
                                    />

                                    {showPopup && (
                                      <div className="map-popup">
                                        <GoogleMap
                                          onLoad={onMapLoadStreet}
                                        ></GoogleMap>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="City"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Country"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <div className="number_inp_wrap">
                                    {/* Phone start */}
                                    <input
                                      type="number"
                                      className="input_nmbr"
                                      placeholder="Phone"
                                    />
                                    <PhoneInput
                                      className="f_1 cntry_code"
                                      international
                                      required
                                      countryCallingCodeEditable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={website} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Website"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <FormControlLabel
                                className="anthr_office_check"
                                control={<Checkbox />}
                                label="Onboard another office"
                                onChange={(e) => setOffice3(e.target.checked)}
                              />
                            </>
                          )}

                          {office3 && (
                            <>
                              <Row className="mt-2">
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={office} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Enter office name *"
                                      name="name"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={chair} alt="" />
                                    <Select
                                      className="w-100 slct_sign"
                                      name="office_type_id"
                                    >
                                      <MenuItem
                                        // disabled
                                        className="selectPlaceholder"
                                        value="option1"
                                      >
                                        Select Office Type{" "}
                                      </MenuItem>
                                      {officetypes &&
                                        officetypes.map((value, index) => {
                                          return (
                                            <MenuItem value={value._id}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6} className="">
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Apartment number/ house name *"
                                      name="pincode"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      placeholder="Post code"
                                      className="addr_custom_inp w-100"
                                      type="textarea"
                                      // onChange={(e) => { handleChange(e) }}
                                      onFocus={handlePopupOpen}
                                      onClick={handlePopupOpen}
                                      ref={searchBoxRefStreet}
                                    />

                                    {showPopup && (
                                      <div className="map-popup">
                                        <GoogleMap
                                          onLoad={onMapLoadStreet}
                                        ></GoogleMap>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="City"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Country"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <div className="number_inp_wrap">
                                    {/* Phone start */}
                                    <input
                                      type="number"
                                      className="input_nmbr"
                                      placeholder="Phone"
                                    />
                                    <PhoneInput
                                      className="f_1 cntry_code"
                                      international
                                      required
                                      countryCallingCodeEditable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={website} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Website"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <FormControlLabel
                                className="anthr_office_check"
                                control={<Checkbox />}
                                label="Onboard another office"
                                onChange={(e) => setOffice4(e.target.checked)}
                              />
                            </>
                          )}

                          {office4 && (
                            <>
                              <Row className="mt-2">
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={office} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Enter office name *"
                                      name="name"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={chair} alt="" />
                                    <Select
                                      className="w-100 slct_sign"
                                      name="office_type_id"
                                    >
                                      <MenuItem
                                        // disabled
                                        className="selectPlaceholder"
                                        value="option1"
                                      >
                                        Select Office Type{" "}
                                      </MenuItem>
                                      {officetypes &&
                                        officetypes.map((value, index) => {
                                          return (
                                            <MenuItem value={value._id}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6} className="">
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Apartment number/ house name *"
                                      name="pincode"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      placeholder="Post code"
                                      className="addr_custom_inp w-100"
                                      type="textarea"
                                      // onChange={(e) => { handleChange(e) }}
                                      onFocus={handlePopupOpen}
                                      onClick={handlePopupOpen}
                                      ref={searchBoxRefStreet}
                                    />

                                    {showPopup && (
                                      <div className="map-popup">
                                        <GoogleMap
                                          onLoad={onMapLoadStreet}
                                        ></GoogleMap>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="City"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Country"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <div className="number_inp_wrap">
                                    {/* Phone start */}
                                    <input
                                      type="number"
                                      className="input_nmbr"
                                      placeholder="Phone"
                                    />
                                    <PhoneInput
                                      className="f_1 cntry_code"
                                      international
                                      required
                                      countryCallingCodeEditable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={website} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Website"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <FormControlLabel
                                className="anthr_office_check"
                                control={<Checkbox />}
                                label="Onboard another office"
                                onChange={(e) => setOffice5(e.target.checked)}
                              />
                            </>
                          )}

                          {office5 && (
                            <>
                              <Row className="mt-2">
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={office} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Enter office name *"
                                      name="name"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={chair} alt="" />
                                    <Select
                                      className="w-100 slct_sign"
                                      name="office_type_id"
                                    >
                                      <MenuItem
                                        // disabled
                                        className="selectPlaceholder"
                                        value="option1"
                                      >
                                        Select Office Type{" "}
                                      </MenuItem>
                                      {officetypes &&
                                        officetypes.map((value, index) => {
                                          return (
                                            <MenuItem value={value._id}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6} className="">
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Apartment number/ house name *"
                                      name="pincode"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      placeholder="Post code"
                                      className="addr_custom_inp w-100"
                                      type="textarea"
                                      // onChange={(e) => { handleChange(e) }}
                                      onFocus={handlePopupOpen}
                                      onClick={handlePopupOpen}
                                      ref={searchBoxRefStreet}
                                    />

                                    {showPopup && (
                                      <div className="map-popup">
                                        <GoogleMap
                                          onLoad={onMapLoadStreet}
                                        ></GoogleMap>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="City"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Country"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <div className="number_inp_wrap">
                                    {/* Phone start */}
                                    <input
                                      type="number"
                                      className="input_nmbr"
                                      placeholder="Phone"
                                    />
                                    <PhoneInput
                                      className="f_1 cntry_code"
                                      international
                                      required
                                      countryCallingCodeEditable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={website} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Website"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <FormControlLabel
                                className="anthr_office_check"
                                control={<Checkbox />}
                                label="Onboard another office"
                                onChange={(e) => setOffice6(e.target.checked)}
                              />
                            </>
                          )}

                          {office6 && (
                            <>
                              <Row className="mt-2">
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={office} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Enter office name *"
                                      name="name"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={chair} alt="" />
                                    <Select
                                      className="w-100 slct_sign"
                                      name="office_type_id"
                                    >
                                      <MenuItem
                                        // disabled
                                        className="selectPlaceholder"
                                        value="option1"
                                      >
                                        Select Office Type{" "}
                                      </MenuItem>
                                      {officetypes &&
                                        officetypes.map((value, index) => {
                                          return (
                                            <MenuItem value={value._id}>
                                              {value.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Form.Group>
                                </Col>
                                <Col md={6} className="">
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Apartment number/ house name *"
                                      name="pincode"
                                      required
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      placeholder="Post code"
                                      className="addr_custom_inp w-100"
                                      type="textarea"
                                      // onChange={(e) => { handleChange(e) }}
                                      onFocus={handlePopupOpen}
                                      onClick={handlePopupOpen}
                                      ref={searchBoxRefStreet}
                                    />

                                    {showPopup && (
                                      <div className="map-popup">
                                        <GoogleMap
                                          onLoad={onMapLoadStreet}
                                        ></GoogleMap>
                                      </div>
                                    )}
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="City"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={location} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Country"
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md={6}>
                                  <div className="number_inp_wrap">
                                    {/* Phone start */}
                                    <input
                                      type="number"
                                      className="input_nmbr"
                                      placeholder="Phone"
                                    />
                                    <PhoneInput
                                      className="f_1 cntry_code"
                                      international
                                      required
                                      countryCallingCodeEditable={true}
                                    />
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <Form.Group className="mb-4 form-group">
                                    <img src={website} alt="" />
                                    <Form.Control
                                      type="text"
                                      className=""
                                      placeholder="Website"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <FormControlLabel
                                className="anthr_office_check"
                                control={<Checkbox />}
                                label="Onboard another office"
                              />
                            </>
                          )}

                          {!show && (
                            <Button
                              className="w-100 theme_btn"
                              type="submit"
                              variant="primary"
                            >
                              Save
                            </Button>
                          )}
                        </div>
                      </form>

                      {show && (
                        <Form onSubmit={NextPage}>
                          <div className="adminDetails sign_section">
                            <p className="onbrdheading sign_hdng">
                              Administrator details
                            </p>
                            <Row className="row_gap_20">
                              <Col md={9}>
                                <Row className="comp_frm_gap">
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                      <img src={office} alt="" />
                                      <Form.Control
                                        type="text"
                                        className=""
                                        // disabled
                                        value={localAdmin?.first_name}
                                        placeholder="Enter first name"
                                        name="first_name"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                      <img src={office} alt="" />
                                      <Form.Control
                                        type="text"
                                        className=""
                                        // disabled
                                        value={localAdmin?.last_name}
                                        placeholder="Enter last name"
                                        name="last_name"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                    {filteredDesignation &&
                                      filteredDesignation.map((item) => {
                                        return (
                                          <Form.Group className="form-group">
                                            <img src={chair} alt="" />
                                            <Form.Control
                                              type="text"
                                              className=""
                                              // disabled
                                              value={item.name}
                                              placeholder="Designation"
                                              name="designation_id"
                                            />

                                            {/* <Select className="w-100" value={AdminDetails.administrator_details.designation_id ? AdminDetails.administrator_details.designation_id : "option1"} name='designation_id' onChange={handleAdministratorChange}>
                                                                            <MenuItem disabled className="selectPlaceholder" value='option1' >Select Designation</MenuItem>
                                                                            <MenuItem value="option2">Designation</MenuItem>
                                                                            <MenuItem value="option3">Option 3</MenuItem>
                                                                        </Select> */}
                                          </Form.Group>
                                        );
                                      })}
                                  </Col>
                                  <Col lg={6} md={12} sm={12} xs={12}>
                                    <Form.Group className="form-group">
                                      <img src={user} alt="" />
                                      <Select
                                        className="w-100 slct_sign"
                                        value={
                                          AdminDetails.administrator_details
                                            .office_name
                                            ? AdminDetails.administrator_details
                                                .office_name
                                            : "option1"
                                        }
                                        name="office_name"
                                        onChange={handleAdministratorChange}
                                      >
                                        <MenuItem
                                          disabled
                                          className="selectPlaceholder"
                                          value="option1"
                                        >
                                          Select Office Name
                                        </MenuItem>
                                        {officeNames &&
                                          officeNames.map((value, index) => {
                                            return (
                                              <MenuItem value={value._id}>
                                                {value.name}
                                              </MenuItem>
                                            );
                                          })}
                                        {/* <MenuItem value="option3">Option 3</MenuItem> */}
                                      </Select>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={3}>
                                <div className="currentPic adm_profile position-relative text-center">
                                  {AdminDetails.administrator_details
                                    .admin_profile === "" && (
                                    <img src={addPic} alt="" />
                                  )}
                                  {AdminDetails.administrator_details
                                    .admin_profile !== "" && (
                                    <img
                                      className="uploaded"
                                      src={
                                        AdminDetails.administrator_details
                                          .admin_profile
                                      }
                                      alt=""
                                    />
                                  )}
                                  {AdminDetails.administrator_details
                                    .admin_profile === "" && (
                                    <span className="mt-2 d-block">
                                      Add current photo
                                    </span>
                                  )}
                                  <input
                                    type="file"
                                    required
                                    onChange={(e) => {
                                      AddAdminProfile(e.target.files[0]);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col md={6}>
                                <Form.Group className="form-group">
                                  <img src={chair} alt="" />
                                  <Select
                                    className="w-100 slct_sign"
                                    value={
                                      AdminDetails.administrator_details
                                        .department
                                        ? AdminDetails.administrator_details
                                            .department
                                        : "option1"
                                    }
                                    name="department"
                                    onChange={handleAdministratorChange}
                                  >
                                    <MenuItem
                                      disabled
                                      className="selectPlaceholder"
                                      value="option1"
                                    >
                                      Select Department
                                    </MenuItem>
                                    {departmentTypes &&
                                      departmentTypes.map((value, index) => {
                                        return (
                                          <MenuItem value={value._id}>
                                            {value.name}
                                          </MenuItem>
                                        );
                                      })}
                                  </Select>
                                </Form.Group>
                              </Col>
                              <Col md={6} className="admn_eml_wrp">
                                <Form.Group className="form-group position-relative w-100">
                                  <img
                                    src={mail}
                                    className="eml_inp_icn"
                                    alt=""
                                  />
                                  <Form.Control
                                    type="email"
                                    required
                                    className=""
                                    value={
                                      AdminDetails.administrator_details
                                        .office_email
                                    }
                                    placeholder="Official email id *"
                                    name="office_email"
                                    onChange={(e) => {
                                      checkEmail(e.target.value);
                                      handleAdministratorChange(e);
                                    }}
                                  />
                                  {emailExist && (
                                    <span
                                      style={{ color: "red" }}
                                      className="eml_txt_dngr"
                                    >
                                      Email Already Exist*
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                              <Col md={6} className="admn_numb_wrap">
                                <div className="number_inp_wrap w-100">
                                  {/* Phone start */}
                                  <input
                                    type="number"
                                    required
                                    className="input_nmbr"
                                    value={
                                      AdminDetails.administrator_details.phone
                                    }
                                    placeholder="Mobile number"
                                    name="phone"
                                    onChange={handleAdministratorChange}
                                  />
                                  <PhoneInput
                                    className="f_1 cntry_code"
                                    international
                                    countryCallingCodeEditable={true}
                                    required
                                    // defaultCountry="RU"
                                    // value={AdminDetails.administrator_details.country_code}
                                    name="country_code"
                                    onChange={(e) => {
                                      setAdminDetails((prev) => ({
                                        ...prev,
                                        administrator_details: {
                                          ...prev.administrator_details,
                                          country_code: e,
                                        },
                                      }));
                                    }}
                                    // readOnly
                                  />
                                </div>
                                {/* <h6 className='text-pink cursor-pointer'>Add another phone number</h6> */}
                              </Col>
                            </Row>
                          </div>
                          <div className="adminDetails sign_section">
                            <p className="onbrdheading sign_hdng">
                              Administrator rights
                            </p>
                            <Row>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox disabled />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_to_onboard_users
                                  }
                                  name="allowed_to_onboard_users"
                                  onChange={handleAdminRights}
                                  label="Allowed to onboard  users"
                                />
                              </Col>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox disabled />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_to_deregister_users
                                  }
                                  name="allowed_to_deregister_users"
                                  onChange={handleAdminRights}
                                  label="Allowed to de-register users "
                                />
                              </Col>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox disabled />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_to_assign_users_rights
                                  }
                                  name="allowed_to_set_financial_limit"
                                  onChange={handleAdminRights}
                                  label="Allowed to assign user rights"
                                />
                              </Col>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox disabled />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_to_set_financial_limit
                                  }
                                  name="allowed_to_set_financial_limit"
                                  onChange={handleAdminRights}
                                  label="Allowed to set financial limits for users"
                                />
                              </Col>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox disabled />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_complete_access
                                  }
                                  name="allowed_complete_access"
                                  onChange={handleAdminRights}
                                  label="Allowed complete access"
                                />
                              </Col>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox disabled />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_to_broadcast_tasks
                                  }
                                  name="allowed_to_broadcast_tasks"
                                  onChange={handleAdminRights}
                                  label="Allowed to broadcast tasks"
                                />
                              </Col>
                              <Col md={4} className="mb-3">
                                <FormControlLabel
                                  className="check_label"
                                  control={<Checkbox />}
                                  checked={
                                    AdminDetails.admin_rights
                                      .allowed_to_purchase_content
                                  }
                                  name="allowed_to_purchase_content"
                                  onChange={handleAdminRights}
                                  label="Allowed to purchase content"
                                />
                              </Col>
                              <Col md={8}>
                                <div className="d-flex set_price">
                                  <p className="mb-0">Set price range</p>
                                  <Form.Group className="mb-4 form-group">
                                    {/* <Select
                                      className="w-100"
                                      value={
                                        AdminDetails.admin_rights.price_range
                                          .minimum_price
                                      }
                                      disabled={
                                        !AdminDetails.admin_rights
                                          .allowed_to_purchase_content
                                          ? true
                                          : false
                                      }
                                      name="minimum_price"
                                      onChange={(e) => {
                                        setAdminDetails((prev) => ({
                                          ...prev,
                                          admin_rights: {
                                            ...prev.admin_rights,
                                            price_range: {
                                              ...prev.admin_rights.price_range,
                                              minimum_price: e.target.value,
                                            },
                                          },
                                        }));
                                      }}
                                    >
                                      <MenuItem
                                        className="selectPlaceholder"
                                        value="no_min"
                                      >
                                        No min
                                      </MenuItem>
                                      <MenuItem value="0">0</MenuItem>
                                      <MenuItem value="10">10</MenuItem>
                                    </Select> */}
                                    <input
                                      type="text"
                                      value={
                                        AdminDetails.admin_rights.price_range
                                          .minimum_price
                                      }
                                      name="minimum_price"
                                      onChange={(e) => {
                                        setAdminDetails((prev) => ({
                                          ...prev,
                                          admin_rights: {
                                            ...prev.admin_rights,
                                            price_range: {
                                              ...prev.admin_rights.price_range,
                                              minimum_price: e.target.value,
                                            },
                                          },
                                        }));
                                      }}
                                      className="form-control minInput"
                                      placeholder="Min"
                                    />
                                  </Form.Group>
                                  <Form.Group className="mb-4 form-group">
                                    {/* <Select
                                      className="w-100"
                                      value={
                                        AdminDetails.admin_rights.price_range
                                          .maximum_price
                                      }
                                      disabled={
                                        !AdminDetails.admin_rights
                                          .allowed_to_purchase_content
                                          ? true
                                          : false
                                      }
                                      name="maximum_price"
                                      onChange={(e) => {
                                        setAdminDetails((prev) => ({
                                          ...prev,
                                          admin_rights: {
                                            ...prev.admin_rights,
                                            price_range: {
                                              ...prev.admin_rights.price_range,
                                              maximum_price: e.target.value,
                                            },
                                          },
                                        }));
                                      }}
                                    >
                                      <MenuItem
                                        className="selectPlaceholder"
                                        value="no_max"
                                      >
                                        No max
                                      </MenuItem>
                                      <MenuItem value="172">172</MenuItem>
                                      <MenuItem value="276">276</MenuItem>
                                    </Select> */}
                                    <input
                                      name="maximum_price"
                                      type="text"
                                      value={
                                        AdminDetails.admin_rights.price_range
                                          .maximum_price
                                      }
                                      onChange={(e) => {
                                        setAdminDetails((prev) => ({
                                          ...prev,
                                          admin_rights: {
                                            ...prev.admin_rights,
                                            price_range: {
                                              ...prev.admin_rights.price_range,
                                              maximum_price: e.target.value,
                                            },
                                          },
                                        }));
                                      }}
                                      className="form-control minInput"
                                      placeholder="Max"
                                    />
                                  </Form.Group>
                                </div>
                              </Col>
                              {/* <Col md={4} className='mb-3'>
                                                                <FormControlLabel className='check_label' control={<Checkbox defaultChecked desabled />} label="Onboard another user" />
                                                            </Col> */}
                            </Row>
                          </div>
                          <div className="stepFooter">
                            <Button
                              className="w-100"
                              type="submit"
                              variant="primary"
                            >
                              Next
                            </Button>
                            <h6 className="text-center mt-3">1 of 4</h6>
                          </div>
                        </Form>
                      )}
                    </div>
                  </div>
                  {/* )} */}
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="rt_col pos_stick position-relative"
              >
                <div className="right-side position-relative">
                  <span className="shape yl_sqr pos-abs"></span>
                  <span className="shape bl_crcl pos_abs"></span>
                  <span className="shape gr_tri pos_abs"></span>
                  <span className="shape rd_crcl pos_abs"></span>
                  <div className="left-side text-center">
                    <img src={accessCenter} className="signupBigImg" alt="" />
                    <h2>
                      Let's start delivering{" "}
                      <span className="txt_bld">news</span>
                    </h2>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <DbFooter />
    </>
  );
};

export default Signup;
