import * as React from "react";
import { CardActions, Button, Card, CardContent, Typography } from "@mui/material";
// import usr1 from '../assets/images/usrimg11.svg';
import { BsArrowRight, BsMic } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import { VscDeviceCameraVideo } from "react-icons/vsc";
import { MdAdd } from "react-icons/md";
import { InputGroup } from "react-bootstrap";
import inpimg from '../assets/images/profile.webp';
import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import presshopchatic from "../assets/images/chat-icons/presshoplogo.svg";
import { auth, db } from "../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
    query,
    orderBy,
    onSnapshot,
    limit,
} from "firebase/firestore";
import Loader from "./Loader";
import { Get, Post } from "../services/user.services";
import moment from "moment";
import { getFirestore } from 'firebase/firestore';
import { getDatabase, set, onValue } from 'firebase/database';
import tickic from "../assets/images/chat-icons/tick.svg";
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from "../firebase";
import io from "socket.io-client";
import photoic from "../assets/images/camera.svg";
import contimg from "../assets/images/img10.jpg";
import contimg1 from "../assets/images/vthumbnail.png";
import videoic from "../assets/images/video.svg";

// rating start
import { Rating } from 'react-simple-star-rating'
import { UserDetails } from "./Utils";

const socket = io.connect("https://uat.presshop.live:3005");

function ChatCardSocket(props) {

    const User = UserDetails
    const [roomId, setRoomId] = useState(null);
    const [msg, setMsg] = useState("")
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState()
    const [profileImage, setProfileImage] = useState()
    const [onlineUsers, setOnlineUsers] = useState([]);
    // const [roomDetails, setRoomDetails] = useState()
    const [file, setFile] = useState(null);
    const [media, setMedia] = useState(null);
    const [taskDetails, setTaskDetails] = useState()
    const [roomDetails, setRoomDetails] = useState()
    const [taskId, setTaskId] = useState()
    const [mediaMessage, setMediaMessage] = useState({
        // room_type: "",
        room_id: "",
        message: "",
        primary_room_id: "",
        sender_id: "",
        message_type: "",
        attachment_name: "",
        attachment_size: "",
        attachment: "",
        receiver_id: ""
    })
    const [review, setReview] = useState("")
    const [rating, setRating] = useState(0)

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
    }

    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value, index) => console.log(value, index)


    const Profile = async () => {
        setLoading(true)
        try {
            setLoading(false)
            const resp = await Get(`mediaHouse/getProfile`)
            setProfileImage(resp.data.profile.admin_detail.admin_profile)
            setName(resp.data.profile.full_name)
        }
        catch (error) {
            setLoading(false)
        }
    }

    const JoinRoom = () => {
        socket.emit("room join", { room_id: roomDetails?.roomsdetails?.room_id })
    }

    // const JoinedRoom = () => {
    //     socket.on('room join', (roomId) => {
    //         // Room successfully joined
    //       });
    // }

    const SendMessage = () => {
        const obj = {
            room_id: roomDetails?.roomsdetails?.room_id,
            receiver_id: roomDetails?.hopper_id._id,
            message: msg,
            sender_id: roomDetails?.task_id?.mediahouse_id
        }
        socket.emit("chat message", obj)
        socket.on("chat message", (obj) => {
        })

        setMsg("")
        getMessages()
    }

    // setTimeout(() => {
    //     getMessages()
    // }, 100000)



    useEffect(() => {
        JoinRoom()
        getMessages()
        // JoinedRoom()
    }, [roomDetails?.roomsdetails?.room_id])

    // const CreateRoom = async () => {
    //     const obj = {
    //         receiver_id: props.senderId,
    //         room_type: "MediahousetoAdmin"
    //         // task_id: props.id
    //     }
    //     const resp = await Post(`mediaHouse/createRoom`, obj)
    //     setRoomDetails(resp.data.details)
    // }
    // const sendMessage = async (event) => {

    //     event.preventDefault();

    //     try {
    //         const { uid, email } = auth?.currentUser;

    //         const messageRef = collection(getFirestore(), 'Chat', roomDetails?.room_id ? (roomDetails?.room_id && roomDetails?.room_id) : (roomDetails.room_id && roomDetails.room_id), 'Messages')
    //         await addDoc(messageRef, {
    //             messageId: new Date(),
    //             date: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
    //             senderId: roomDetails?.sender_id,
    //             senderName: roomDetails?.senderDetails?.first_name + " " + roomDetails?.senderDetails?.last_name,
    //             senderImage: roomDetails?.senderDetails?.profile_image,
    //             receiverId: roomDetails?.receiver_id,
    //             receiverName: roomDetails?.receiverDetails?.name,
    //             receiverImage: roomDetails?.receiverDetails?.profile_image,
    //             roomId: roomDetails?.room_id,
    //             replyMessage: "Empty Comming Soon",
    //             messageType: "text",
    //             message: msg,
    //             videoThumbnail: "",
    //             uploadPercent: 0.0,
    //             readStatus: "unread",
    //             replyType: "text",
    //             latitude: 0.0,
    //             longitude: 0.0,
    //             isReply: "",
    //             isLocal: 1,
    //             media: media,
    //             uid,
    //         });
    //         setMsg('');
    //         setFile(null)
    //         setMedia(null)
    //         // GetMessages()
    //     }
    //     catch (error) {
    //         // setLoading(false)
    //     }
    // };

    // const GetMessages = () => {
    //     if (roomDetails?.room_id) {
    //         const messageRef = collection(getFirestore(), 'Chat', roomDetails?.room_id && roomDetails?.room_id, 'Messages')
    //         const q = query(messageRef, orderBy('date'));
    //         const unsubscribe = onSnapshot(q, (snapshot) => {
    //             const newMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //             setMessages(newMessages);
    //         });
    //         return unsubscribe;
    //     }
    //     else if (roomDetails?.room_id) {
    //         const messageRef = collection(getFirestore(), 'Chat', roomDetails?.room_id && roomDetails?.room_id, 'Messages')

    //         const q = query(messageRef, orderBy('date'));
    //         const unsubscribe = onSnapshot(q, (snapshot) => {
    //             const newMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //             setMessages(newMessages);
    //         });

    //         return unsubscribe;
    //     }
    // }

    const getMessages = async () => {
        const resp = await Post(`mediaHouse/getAllchat`, { room_id: roomDetails?.roomsdetails?.room_id })
        setMessages(resp.data.response)
    }

    const handleFileChange = async (event) => {
        event.preventDefault()
        const formdata = new FormData()
        formdata.append("media", event.target.files[0])
        formdata.append("path", "chatMedia")
        // const selectedFile = event.target.files[0];
        // setFile(selectedFile);

        const resp = await Post(`mediaHouse/uploadUserMedia`, formdata)
        if (resp) {
            setMediaMessage({
                // room_type: "individual",
                room_id: roomDetails.roomsdetails.room_id,
                message: msg,
                primary_room_id: roomDetails.roomsdetails._id,
                sender_id: roomDetails.roomsdetails.receiver_id,
                message_type: "media",
                attachment_name: event.target.files[0].name,
                attachment_size: event.target.files[0].size,
                attachment: resp.data.path,
                receiver_id: roomDetails.roomsdetails.sender_id
            })
        }
    };

    const SendMedia = () => {

        socket.emit("media message", mediaMessage)
        socket.on("media message", (obj) => {
        })

        setMediaMessage({
            room_type: "",
            room_id: "",
            message: "",
            primary_room_id: "",
            sender_id: "",
            message_type: "",
            attachment_name: "",
            attachment_size: "",
            attachment: "",
            receiver_id: ""
        })
        setMsg("")
        getMessages()
    }

    const staticPayment = async (data) => {

        const obj = {
            image_id: data.image_id,
            sender_id: data.sender_id._id,
            receiver_id: data.receiver_id._id,
            room_id: data.room_id,
            sender_type: "mediahouse",
            amount: data.media.amount,
            message_type: "buy"
        }
        const resp = await Post('mediahouse/buyuploadedcontent', obj)
        if (resp) {
            getMessages()
        }
    }

    const stripePayment = async (curr) => {

        let obj = {
            image_id: curr?.image_id,
            customer_id: UserDetails.stripe_customer_id,
            amount: curr?.media?.amount,
            type: "task_content",
            task_id: taskId
        }
        const resp = await Post('mediahouse/createPayment', obj)
        window.open(resp.data.url, '_blank')
        if (resp) {
        }
    }

    const DownloadContent = async (id) => {
        const resp = await Get(`mediahouse/image_pathdownload?image_id=${id}`)
        if (resp) {
            const filename = resp.data.message.slice(85)
            // const downloadWindow = window.open(resp.data.message, '_blank');
            // downloadWindow.opener = null;

            fetch(resp.data.message)
                .then(response => response.blob())
                .then(blob => {
                    const downloadElement = document.createElement('a');
                    const url = URL.createObjectURL(blob);
                    downloadElement.href = url;
                    downloadElement.download = filename;
                    downloadElement.click();
                    URL.revokeObjectURL(url);
                });
        }
    }

    // const handleSend = (event) => {
    //     event.preventDefault()

    //     let obj = {

    //     }

    //     if (file !== null) {
    //         socket.emit("media message", obj)
    //         socket.on("chat message", (obj) => {
    //         })
    //     }

    // };


    // useEffect(() => {
    //     // Generate a random room ID and set it in state
    //     const roomId = Math.random().toString(36).substring(2, 8);
    //     setRoomId(roomId);

    //     // Add a new message to the chat collection for this room
    //     const messageRef = collection(getFirestore(), 'Msgs', "0000-00000-0000-0000", 'Messages');
    //     // addDoc(messageRef, {
    //     //     text: 'Welcome to the chat room!',
    //     //     timestamp: new Date()
    //     // });

    //     // Listen for new messages in the chat collection for this room
    //     const q = query(messageRef, orderBy('createdAt'));
    //     const unsubscribe = onSnapshot(q, (snapshot) => {
    //         const newMessages = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    //         setMessages(newMessages);
    //     });

    //     const userId = auth?.currentUser?.uid;
    //     const userRef = ref(getDatabase(), `users/${userId}`);
    //     const presenceRef = ref(getDatabase(), '.info/connected');
    //     onValue(presenceRef, (snapshot) => {
    //         if (snapshot.val() === true) {
    //             // Set the user's presence status to "online"
    //             set(userRef, { presence: 'online' });

    //             // Listen for changes to the online users list
    //             const onlineUsersRef = ref(getDatabase(), 'onlineUsers');
    //             onValue(onlineUsersRef, (snapshot) => {
    //                 const onlineUsers = snapshot.val() || [];
    //                 setOnlineUsers(onlineUsers);
    //             });
    //         } else {
    //             // Set the user's presence status to "offline"
    //             set(userRef, { presence: 'offline' });
    //         }
    //     });

    //     // Clean up the subscription when the component unmounts
    //     return unsubscribe;
    // }, []);

    // const handleNewMessageChange = (event) => {
    //     setNewMessageText(event.target.value);
    // };

    // useEffect(() => {
    //     GetMessages()
    // }, [roomDetails?.room_id, roomDetails?.room_id])


    // request more content 

    const requestMoreContent = (curr) => {

        try {
            let obj = {
                room_id: curr?.room_id,
                sender_id: curr?.sender_id?._id,
                receiver_id: curr?.receiver_id?._id,
                sender_type: "mediahouse",
                message_type: 'request_more_content',
            }

            socket.emit("offer message", obj)
            socket.on("offer message", (obj) => {
                getMessages()
            })



        } catch (error) {
        }
    }

    const [hoppers, setHoppers] = useState([])
    const GetHoppers = async () => {
        setHoppers([])
        try {
            const resp = await Get(`mediaHouse/findacceptedtasks?task_id=${props.id && props.id}&receiver_id=${User && User._id}&type=$task_content`)
            if (resp) {
                setHoppers(resp.data.response)
            }
            else {
            }
        }
        catch (error) {
        }

    }



    const TaskDetails = async () => {
        // setLoading(true)
        try {
            const resp = await Get(`mediaHouse/live/expired/tasks?status=live&id=${props.id && props.id}`)
            setTaskDetails(resp.data.tasks)
            setTaskId(resp?.data?.tasks?._id)
        }
        catch (error) {
            //   setLoading(false)
        }
    }

    const RatingNReview = (curr) => {

        const obj = {
            room_id: curr.room_id,
            sender_type: "Mediahouse",
            receiver_id: curr.receiver_id._id,
            sender_id: curr.sender_id._id,
            rating: rating,
            review: review,
            chat_id: messages && messages.find((obj) => obj.message_type === "rating_mediaHouse")._id,
            type: "task_content",
            image_id: curr.image_id
        }
        socket.emit("rating", obj)
        socket.on("rating", (obj) => {
        })
        getMessages(props.room_details.room_id)
    }



    useEffect(() => {
        TaskDetails()
    }, [props.id])

    useEffect(() => {
        GetHoppers()
    }, [props.id])






    useEffect(() => {
        Profile()
    }, [])

    // useEffect(() => {
    //     CreateRoom()
    // }, [props.senderId])

    return (
        <>
            {loading && <Loader />}

            <div className="d-flex gap_20">
                <Card className="chatmain cht_ht">
                    <CardContent className="chatting">
                        <div className="chatting_header">
                            <p className="mb-0">
                                {/* Presshop chat */}
                                Manage Task
                            </p>
                        </div>
                        <div className="chat_msgs_scrl">
                            {/* {messages && messages.map((curr, index) => {
                        const today = new Date()
                        const secondDate = new Date(curr.current_time);
                        return ( */}
                            {roomDetails &&
                                <div className="chatting_itm sngl_cht d-flex align-items-start" >
                                    <img src={presshopchatic} alt="User" className="usr_img" />
                                    <div className="cht_txt">
                                        <div className="d-flex align-items-center">
                                            <p className="usr_name mb-0">Presshop
                                                {/* <img src={presshopchatic} alt="Presshop logo" className='ms-1' /> */}
                                            </p>
                                            <p className="cht_time mb-0">26 may, 2023</p>
                                        </div>
                                        <p className="mb-0 msg">This task has been accepted by Pseudonymous</p>
                                        <div className="ofr_crd position-relative">
                                            <img src={tickic} alt="Accepted" className="acpte" />
                                            <p className="tsk_stts">Task Accepted</p>
                                            <p className="tsk_descr">{taskDetails.task_description}</p>
                                            <div className="btm_btns d-flex justify-content-between">
                                                <div className="sngl_btn">
                                                    <p className="prc">{taskDetails.need_photos === true ? "£" + taskDetails.photo_price : "--"}</p>
                                                    <p className="offrd_txt">
                                                        Offered</p>
                                                    <div className="cont_tp">
                                                        Picture
                                                    </div>
                                                </div>
                                                <div className="sngl_btn">
                                                    <p className="prc">{taskDetails.need_interview === true ? "£" + taskDetails.interview_price : "--"}</p>
                                                    <p className="offrd_txt">
                                                        Offered</p>
                                                    <div className="cont_tp">
                                                        Interview
                                                    </div>
                                                </div>
                                                <div className="sngl_btn">
                                                    <p className="prc">{taskDetails.need_videos === true ? "£" + taskDetails.videos_price : "--"}</p>
                                                    <p className="offrd_txt">
                                                        Offered</p>
                                                    <div className="cont_tp">
                                                        Video
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* {props.roomDetails &&
                        <div className="chatting_itm sngl_cht d-flex align-items-start" >
                            <img src={usr1} alt="User" className="usr_img" />
                            <div className="cht_txt">
                                <div className="d-flex align-items-center">
                                    <p className="usr_name mb-0">Pseudonymous
                                        <img src={presshopchatic} alt="Presshop logo" className='ms-1' /></p>
                                    <p className="cht_time mb-0">26 may, 2023</p>
                                </div>
                                <p className="mb-0 msg">Has uploaded 1 photo</p>
                                <div className="content_uplded position-relative">
                                    <span className="cont_tp">
                                        <img src={photoic} alt="Content type" />
                                    </span>
                                    <img src={contimg} className="usr_upld_cont" alt="Content Image" />
                                </div>
                                <div className="usr_upld_opts">
                                    <button className="theme_btn">
                                        Buy
                                    </button>
                                    <span>or</span>
                                    <button className="secondary_btn">
                                        Request more content
                                    </button>
                                </div>
                                <p className="buy_btn_txt mb-0">This content has been directly uploaded by the Hopper on our platform. We have not reviewed the content for authenticity & privacy, and are not responsible. Please review the content properly before purchasing it. Please  <a className="link">
                                    contact us
                                </a>
                                    should you wish to discuss this content.</p>
                            </div>
                        </div>
                    } */}
                            {/* {messages && messages.map((curr, index) => {
                        const today = new Date()
                        const secondDate = new Date(curr.current_time);
                        return (
                            <div className="chatting_itm d-flex align-items-start" >
                                <img src={curr.senderImage} alt="User" className="usr_img" />
                                <div className="cht_txt">
                                    <div className="d-flex align-items-center">
                                        <p className="usr_name mb-0">{curr.senderName}
                                            <img src={presshopchatic} alt="Presshop logo" className='ms-1' /></p>
                                        <p className="cht_time mb-0">{moment.utc(curr.date).local().format('hh:mm:A')}</p>
                                    </div>
                                    {curr.media !== null ?
                                        < img src={curr.media} alt="User" className="usr_img" />
                                        :
                                        <p className="mb-0 msg">{curr.message}</p>}
                                </div>
                            </div>
                        )
                    })} */}
                            {messages && messages.map((curr, index) => {
                                const Ratingg = messages && messages.find(item => item?.message_type === "rating_mediaHouse");
                                const Ratings = Ratingg ? Ratingg?.rating : ""
                                return (
                                    <>

                                        {curr.message_type === "media" && <div className="chatting_itm sngl_cht d-flex align-items-start" >
                                            <img src={process.env.REACT_APP_AVATAR_IMAGE + curr?.sender_id?.avatar_id?.avatar} alt="User" className="usr_img" />
                                            <div className="cht_txt">
                                                <div className="d-flex align-items-center">
                                                    <p className="usr_name mb-0">{curr?.sender_id?.user_name}
                                                        {/* <img src={presshopchatic} alt="Presshop logo" className='ms-1' /> */}
                                                    </p>
                                                    <p className="cht_time mb-0">26 may, 2023</p>
                                                </div>
                                                <p className="mb-0 msg">Has uploaded 1 {curr?.media?.thumbnail_url ? "Video" : "Image"}</p>
                                                <div className="content_uplded position-relative">
                                                    <span className="cont_tp">
                                                        <img src={photoic} alt="Content type" />
                                                    </span>
                                                    {curr?.media?.thumbnail_url ?
                                                        <img src={process.env.REACT_APP_UPLOADED_CONTENT + curr?.media?.thumbnail_url} className="usr_upld_cont" alt="Content Image" />
                                                        :
                                                        <img src={process.env.REACT_APP_UPLOADED_CONTENT + curr?.media?.name} className="usr_upld_cont" alt="Content Image" />
                                                    }
                                                </div>
                                                <div className="usr_upld_opts">
                                                    {curr?.paid_status !== true ?
                                                        <button className="theme_btn" onClick={() => {
                                                            // staticPayment(curr)
                                                            stripePayment(curr)
                                                        }}>
                                                            Buy
                                                        </button>
                                                        : ""
                                                    }
                                                    {curr?.paid_status !== true && curr?.request_sent === null && <span>or</span>}
                                                    {/* {
                                            curr?.re
                                            <button className="secondary_btn" onClick={() => requestMoreContent(curr)}>
                                                Request more content
                                            </button>

                                           } */}

                                                    {curr?.request_sent === null && <button className="secondary_btn" onClick={() => requestMoreContent(curr)}>
                                                        Request more content
                                                    </button>}
                                                </div>
                                                <p className="buy_btn_txt mb-0">This content has been directly uploaded by the Hopper on our platform. We have not reviewed the content for authenticity & privacy, and are not responsible. Please review the content properly before purchasing it. Please  <a className="link">
                                                    contact us
                                                </a>
                                                    should you wish to discuss this content.</p>
                                            </div>
                                        </div>}

                                        {curr?.paid_status === true && curr.message_type === "buy" && <div className="chatting_itm auto_msg sngl_cht d-flex align-items-start" >
                                            <img src={presshopchatic} alt="User" className="usr_img" />
                                            <div className="cht_txt">
                                                <div className="d-flex align-items-center">
                                                    <p className="usr_name mb-0">Presshop
                                                        {/* <img src={presshopchatic} alt="Presshop logo" className='ms-1' /> */}
                                                    </p>
                                                    <p className="cht_time mb-0">26 may, 2023</p>
                                                </div>
                                                <p className="mb-0 msg auto_press_msg">Congrats, you’ve successfully purchased 1 {curr?.thumbnail_url ? "video" : "photo"} for £{curr?.amount} from {curr?.sender_id?.user_name}. Please download the water-mark free, and  high definition content, by clicking below</p>
                                                <div className="usr_upld_opts">
                                                    <button className="theme_btn" onClick={() => DownloadContent(curr?.image_id)}>
                                                        Download
                                                    </button>
                                                </div>
                                                <p className="buy_btn_txt mb-0">Please refer to our <a className="link">licensing terms of usage</a>, and <a className="link">terms and conditions</a>. If you have any questions, please <a className="link">chat</a> or <a className="link">contact</a> our helpful teams who are available 24x7 to assist you. Thank you.</p>
                                            </div>
                                        </div>}
                                        {curr.message_type === "request_more_content" && <div className="chatting_itm auto_msg sngl_cht d-flex align-items-start" >
                                            <img src={curr?.receiver_id?.profile_image} alt="User" className="usr_img" />
                                            <div className="cht_txt">
                                                <div className="d-flex align-items-center">
                                                    <p className="usr_name mb-0">{curr?.receiver_id?.first_name + " " + curr?.receiver_id?.last_name}
                                                        {/* <img src={presshopchatic} alt="Presshop logo" className='ms-1' /> */}
                                                    </p>
                                                    <p className="cht_time mb-0">26 may, 2023</p>
                                                </div>
                                                <p className="mb-0 msg auto_press_msg">Has requested for more content from {curr?.sender_id?.user_name}</p>
                                            </div>
                                        </div>}

                                        {curr.message_type === "rating_mediaHouse" && curr.paid_status &&
                                            <div className="chatting_itm auto_msg rating sngl_cht d-flex align-items-start" >
                                                <img src={presshopchatic} alt="User" className="usr_img" />
                                                <div className="cht_txt">
                                                    <div className="d-flex align-items-center">
                                                        <p className="usr_name mb-0">Presshop
                                                        </p>
                                                        <p className="cht_time mb-0">26 may, 2023</p>
                                                    </div>
                                                    <p className="mb-0 msg auto_press_msg">Rate your experience with Pseudonymous</p>
                                                    <div className="usr_upld_opts">
                                                        <Rating
                                                            onClick={handleRating}
                                                            // onPointerEnter={onPointerEnter}
                                                            // onPointerLeave={onPointerLeave}
                                                            // onPointerMove={onPointerMove}
                                                            disabled={!Number(Ratings)}
                                                            initialValue={Ratings ? Number(Ratings) : 0}
                                                            value={rating}
                                                        />
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Control placeholder="Write your review" disabled={curr.review} value={curr.review ? curr.review : review} onChange={(e) => {
                                                                setReview(e.target.value)
                                                            }} as="textarea" rows={3} >

                                                            </Form.Control>
                                                        </Form.Group>
                                                        {!curr.rating && <button className="theme_btn" onClick={() => RatingNReview(curr)}>
                                                            Submit
                                                        </button>}
                                                    </div>
                                                </div>
                                            </div>}
                                        {curr.message_type === "reject_mediaHouse_offer" && !curr.paid_status &&
                                            <div className="chatting_itm auto_msg rating sngl_cht d-flex align-items-start" >
                                                <img src={presshopchatic} alt="User" className="usr_img" />
                                                <div className="cht_txt">
                                                    <div className="d-flex align-items-center">
                                                        <p className="usr_name mb-0">
                                                            Presshop
                                                        </p>
                                                        <p className="cht_time mb-0">26 may, 2023</p>
                                                    </div>
                                                    <p className="mb-0 msg auto_press_msg">Rate your experience with Pseudonymous</p>
                                                    <div className="usr_upld_opts">
                                                        <Rating
                                                            onClick={handleRating}
                                                            // onPointerEnter={onPointerEnter}
                                                            // onPointerLeave={onPointerLeave}
                                                            // onPointerMove={onPointerMove}
                                                            value={rating}
                                                            disabled={!Number(Ratings)}
                                                            // initialValue={Ratingg ? Number(Ratings) : rating}
                                                            initialValue={Ratings ? Number(Ratings) : 0}
                                                        // defaultValue={3}
                                                        />
                                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                            <Form.Control placeholder="Write your review" disabled={curr.review} value={curr.review ? curr.review : review} onChange={(e) => {
                                                                setReview(e.target.value)
                                                            }} as="textarea" rows={3} >

                                                            </Form.Control>
                                                        </Form.Group>
                                                        <button className="theme_btn" onClick={() => RatingNReview(curr.image_id)}>
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>}


                                        {/* <div className="chatting_itm auto_msg rating sngl_cht d-flex align-items-start" >
                                    <img src={presshopchatic} alt="User" className="usr_img" />
                                    <div className="cht_txt">
                                        <div className="d-flex align-items-center">
                                            <p className="usr_name mb-0">Presshop
                                            </p>
                                            <p className="cht_time mb-0">26 may, 2023</p>
                                        </div>
                                        <p className="mb-0 msg auto_press_msg">Rate your experience with Pseudonymous</p>
                                        <div className="usr_upld_opts">
                                            <Rating
                                                onClick={handleRating}
                                                onPointerEnter={onPointerEnter}
                                                onPointerLeave={onPointerLeave}
                                                onPointerMove={onPointerMove}
                                            />
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Control placeholder="Write your review" as="textarea" rows={3} >

                                                </Form.Control>
                                            </Form.Group>
                                            <button className="theme_btn">
                                                Write a Review
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                                    </>
                                )
                            })}
                        </div>
                    </CardContent>
                    {/* <Form onSubmit={(event) => {
                        event.preventDefault()
                        // if (file !== null) {
                        //     handleSend(event)
                        //     // sendMessage(event)
                        // }
                        if (mediaMessage.attachment !== "") {
                            SendMedia(mediaMessage)
                        }
                        else if (msg !== "" && mediaMessage.attachment === "") {
                            SendMessage()
                        }
                        // 
                    }}>
                        <div className="chatting_type position-relative">
                            <img src={inpimg} alt="" className="typing_img" />
                            <input type="text" className="inp_msg" value={mediaMessage.attachment_name !== "" ? mediaMessage.attachment_name : msg} placeholder="Type here…" onChange={(e) => { setMsg(e.target.value) }} />
                            <div className="chatIn-options">
                                <input type='file' id="cht_add_img" className="cht_file_inp" onChange={handleFileChange} />
                                <label htmlFor="cht_add_img" className="cht_fl_inp_lbl">
                                    <MdAdd className="d_flex file_add_icn" />
                                </label>
                                <VscDeviceCameraVideo />
                                <IoCallOutline />
                                <BsMic />
                                <Button type="submit" className='pe-0'>
                                    <span className='chatIn-send'>
                                        <BsArrowRight />
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Form > */}
                </Card >
                <Card className="chatmain participants">
                    <CardContent className="chatting">
                        <div className="chatting_header d-flex align-items-start justify-content-between">
                            <p className="mb-0">Hoppers</p>
                            {/* <span className="add_icn"><MdAdd /></span> */}
                        </div>
                        <div className="chat_content_list">
                            {hoppers && hoppers.map((curr) => {
                                return (
                                    <div className="chatting_itm d-flex align-items-center" onClick={() => setRoomDetails(curr)}>
                                        <img src={process.env.REACT_APP_AVATAR_IMAGE + curr.avatar_detals[0].avatar} alt="User" className="usr_img" />
                                        <div className="cht_txt w-100">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="usr_name mb-0">{curr.hopper_id.user_name}</p>
                                                <div className="prtcpnt_opt">
                                                    <span>view</span>
                                                    <span>Message</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <div className="d-flex justify-content-end mt-3">
                            <Link className="view_all_link">View all <BsArrowRight className='text-pink' /></Link>
                        </div> */}
                        </div>
                        {/* Experiment start */}
                        <div className="chatting_header d-flex align-items-start justify-content-between mt-5 mb-3">
                            <p className="mb-0 ">Participants</p>
                            <span className="add_icn" ><Link to="/manage-users"><MdAdd /></Link></span>
                        </div>
                        <div className="chat_content_list">
                            {hoppers && hoppers.map((curr) => {
                                return (
                                    <div className="chatting_itm d-flex align-items-center" onClick={() => setRoomDetails(curr)}>
                                        <img src={process.env.REACT_APP_AVATAR_IMAGE + curr.avatar_detals[0].avatar} alt="User" className="usr_img" />
                                        <div className="cht_txt w-100">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <p className="usr_name mb-0">{curr.hopper_id.user_name}</p>
                                                <div className="prtcpnt_opt">
                                                    <span>view</span>
                                                    <span>Message</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {/* <div className="d-flex justify-content-end mt-3">
                            <Link className="view_all_link">View all <BsArrowRight className='text-pink' /></Link>
                        </div> */}
                        </div>
                        {/* Experiment end */}
                    </CardContent>
                </Card>
            </div>
        </>
    );
}
export default React.memo(ChatCardSocket);
