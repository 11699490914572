import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Header from "../component/Header"
import imgs from "../assets/images/imgn6.jpg";
import img2 from "../assets/images/img2.webp";
import contentCamera from "../assets/images/contentCamera.svg";
import contentVideo from "../assets/images/contentVideo.svg";
import avatar from "../assets/images/avatar.png";
import map from "../assets/images/map.svg";
import ContentFeedCard from '../component/card/ContentFeedCard';
import shared from '../assets/images/share.png';
import exclusive from '../assets/images/exclusive.png';
import { Select, MenuItem } from '@mui/material';
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Container, Row, Col } from 'react-bootstrap';
import TopSearchesTipsCard from "../component/card/TopSearchesTipsCard";
import { Get, Patch, Post } from "../services/user.services";
import moment from 'moment/moment';
import favic from "../assets/images/star.svg";
import favouritedic from "../assets/images/favouritestar.svg";
import Loader from '../component/Loader';
// import audioic from "../assets/images/audio-icon.svg";
import audioic from "../assets/images/audimg.svg";
import Fundsinvested from '../component/Sortfilters/Dashboard/Fundsinvested';
import TopFilterComn from '../component/Sortfilters/Content/TopFilterComn';
import { AiFillCaretDown } from 'react-icons/ai';
import videoic from "../assets/images/video.svg";
import interviewic from "../assets/images/interview.svg";
import cameraic from "../assets/images/camera.svg";
import DbFooter from '../component/DbFooter';
import docsic from "../assets/images/docsic.svg";
import pdfic from "../assets/images/pdfic.svg";

const PublishedContent = (props) => {
    // console.log("props.locations", props.location)
    const [fav, setFav] = useState(false);
    const [loading, setLoading] = useState(false);
    const [crime, setCrime] = useState();
    const [cel, setCel] = useState();
    const handleFavourite = () => {
        setFav(!fav);
        PublishContent();
    }
    // Sort and Filter Open and Close Component
    const [openSortComponent, setOpenSortComponent] = useState(false);
    const [openFilterComponent, setOpenFilterComponent] = useState(false);

    const handleCloseFilterComponent = (values) => {
        setOpenFilterComponent(values);
    }

    const handleCloseSortComponent = (values) => {
        setOpenSortComponent(values);
    }

    const [sortValues, setSortValues] = useState("");

    const [sortValuesName, setSortValuesName] = useState("");

    const handleSortValues = (values) => {
        setSortValues(values?.values);
        // console.log("handleSortValues 55", values?.values);
        setSortValuesName(values?.values)
    }



    const [multiFilter, setMultiFilter] = useState([]);
    const [filterParams, setFilterParams] = useState({ type: [], category_id: [], content_under_offer: "" });

    const handleMultiFilter = (values) => {
        setMultiFilter(values);

        const categoryFilter = values?.filter((el) => el.field === "category_id");
        const typeFilter = values?.filter((el) => el.field === "type");
        const contentUnderOffer = values?.find((el) => el.field === "content_under_offer")?.values;

        setFilterParams({
            category_id: categoryFilter,
            type: typeFilter,
            content_under_offer: contentUnderOffer,
        });
    };

    const [PublishedData, setPublishedData] = useState({
        all: [],
        exclusive: [],
        shared: [],
        crime: [],
        celebrity: [],
    });

    const PublishContent = async () => {
        setLoading(true)
        try {
            if (filterParams.type.length === 0 && filterParams.category_id.length === 0 && !filterParams.content_under_offer) {

                const all = await Post("mediaHouse/view/published/content", { content: "latest", sortValuesName: sortValues });
                const exclusive = await Post("mediaHouse/view/published/content", { type: ["exclusive"], sortValuesName: sortValues });
                const shared = await Post("mediaHouse/view/published/content", { type: ["shared"], sortValuesName: sortValues });
                const crime = await Post("mediaHouse/view/published/content", { category_id: ["64f09d79db646e4f7791761b"], sortValuesName: sortValues });
                const celebrity = await Post("mediaHouse/view/published/content", { category_id: ["64f09d1fdb646e4f779174a1"], sortValuesName: sortValues });
                setLoading(false)
                // for all
                const mostViewd = all?.data?.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(0, 5);
                const mostPopular = all?.data?.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(6, 15);
                all?.data?.content?.forEach((obj) => {
                    if (mostViewd.includes(obj)) {
                        obj.mostviewed = true;
                    }
                });
                all?.data?.content?.forEach((obj) => {
                    if (mostPopular.includes(obj)) {
                        obj.mostPopular = true;
                    }
                });

                // for exclusive
                const mostViewd1 = exclusive.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(0, 5);
                const mostPopular1 = exclusive.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(6, 15);
                exclusive.data.content?.forEach((obj) => {
                    if (mostViewd1.includes(obj)) {
                        obj.mostviewed = true;
                    }
                });
                exclusive.data.content?.forEach((obj) => {
                    if (mostPopular1.includes(obj)) {
                        obj.mostPopular = true;
                    }
                });

                // shared

                const mostViewd2 = shared.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(0, 5);
                const mostPopular2 = shared.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(6, 15);
                shared.data.content?.forEach((obj) => {
                    if (mostViewd2.includes(obj)) {
                        obj.mostviewed = true;
                    }
                });
                shared.data.content?.forEach((obj) => {
                    if (mostPopular2.includes(obj)) {
                        obj.mostPopular = true;
                    }
                });

                // crime

                const mostViewd3 = crime.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(0, 5);
                const mostPopular3 = crime.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(6, 15);
                crime.data.content?.forEach((obj) => {
                    if (mostViewd3.includes(obj)) {
                        obj.mostviewed = true;
                    }
                });
                crime.data.content?.forEach((obj) => {
                    if (mostPopular3.includes(obj)) {
                        obj.mostPopular = true;
                    }
                });

                // celebrity
                const mostViewd4 = celebrity.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(0, 5);
                const mostPopular4 = celebrity.data.content?.slice()?.sort((a, b) => b.content_view_count - a.content_view_count)?.slice(6, 15);
                celebrity.data.content?.forEach((obj) => {
                    if (mostViewd4.includes(obj)) {
                        obj.mostviewed = true;
                    }
                });
                celebrity.data.content?.forEach((obj) => {
                    if (mostPopular4.includes(obj)) {
                        obj.mostPopular = true;
                    }
                });

                setPublishedData((prev) => ({

                    ...prev,
                    all: all.data.content,
                    exclusive: exclusive.data.content,
                    shared: shared.data.content,
                    crime: crime.data.content,
                    celebrity: celebrity.data.content,

                }));
            } else {
                if (filterParams.type.length === 0) {
                    const result = await Post("mediaHouse/view/published/content", {
                        category_id: filterParams.category_id?.map((el) => el.values),
                        content_under_offer: filterParams.content_under_offer,
                    });
                    const sharedData = result?.data?.content?.filter((el) => el.type === "shared");
                    const exclusiveData = result?.data?.content?.filter((el) => el.type === "exclusive");
                    const celebrityData = result?.data?.content?.filter((el) => el.category_id?._id === "64f09d1fdb646e4f779174a1");
                    const crimeData = result?.data?.content?.filter((el) => el.category_id?._id === "64f09d79db646e4f7791761b");

                    setLoading(false)
                    setPublishedData({
                        exclusive: exclusiveData,
                        shared: sharedData,
                        celebrity: celebrityData,
                        crime: crimeData,
                    });
                } else {
                    const result = await Post("mediaHouse/view/published/content", {
                        type: filterParams.type?.map((el) => el.values),
                        category_id: filterParams.category_id?.map((el) => el.values),
                        content_under_offer: filterParams.content_under_offer,
                    });
                    const sharedData = result?.data?.content?.filter((el) => el.type === "shared");
                    const exclusiveData = result?.data?.content?.filter((el) => el.type === "exclusive");
                    const celebrityData = result?.data?.content?.filter((el) => el.category_id?._id === "64f09d1fdb646e4f779174a1");
                    const crimeData = result?.data?.content?.filter((el) => el.category_id?._id === "64f09d79db646e4f7791761b");
                    setPublishedData(
                        {
                            exclusive: exclusiveData,
                            shared: sharedData,
                            celebrity: celebrityData,
                            crime: crimeData,
                        });
                        setLoading(false)
                }
            }
        } catch (error) {
            // Handle errors here
            console.error(error);
            setLoading(false)
        }
    };


    useEffect(() => {
        // Make the API call when filterParams changes
        PublishContent();
    }, [filterParams, sortValues]);

    // API for category id
    const getCategory = async () => {
        try {
            const result = await Get("mediaHouse/getCategoryType?type=content");
            // console.log("result 66", result?.data);
        } catch (error) {
            // console.log(error);
        }
    }

    useEffect(() => {
        getCategory();
    }, [])

    const formatAmountInMillion = (amount) =>
        amount?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
        });

    return (
        <>
            {/* {console.log(sortValues, `<<<<<<<sort values`)} */}

            {loading && <Loader />}
            <Header />
            <div className="feedTags_search">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <div className="feedPreviews d-flex justify-content-between flex-wrap">
                                {/* <div className="feedHdTags_wrap">
                                    <span className='tag_select'>
                                        <img src={map} className="me-3" alt="" />
                                        Map View</span>
                                </div> */}

                                {
                                    localStorage.getItem("backBtnVisibility") ? <Link onClick={() => history.back()}
                                        className='back_link mb-3'><BsArrowLeft className='text-pink' />
                                        Back
                                    </Link> : <div></div>
                                }

                                <div className="sorting_wrap d-flex">


                                    <div className="feedSorting me-4">

                                        <div className="fltrs_prnt top_fltr">
                                            <p className="lbl_fltr">
                                                Filter
                                            </p>
                                            <button className='sortTrigger' onClick={() => { setOpenFilterComponent(true); }}>Filter <AiFillCaretDown /></button>
                                            {
                                                openFilterComponent && <TopFilterComn
                                                    closeFilterComponent={handleCloseFilterComponent}
                                                    feedMultiFilter={handleMultiFilter}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="feedSorting">
                                        <div className="fltrs_prnt top_fltr">
                                            <p className="lbl_fltr">Sort</p>
                                            <button className='sortTrigger' onClick={() => { setOpenSortComponent(true); }}>Sort <AiFillCaretDown /></button>
                                            {
                                                openSortComponent && <Fundsinvested
                                                    rangeTimeValues={handleSortValues}
                                                    closeSortComponent={handleCloseSortComponent}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="page-wrap">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <div className="feedsMain_wrap">
                                <div className="feedsContainer">
                                    {
                                        PublishedData?.all && PublishedData?.all?.length > 0 && <div className="feedContent_header">
                                            <h1 className='rw_hdng'>Latest content</h1>
                                            <Link to="/Uploaded-Content/all">View all <BsArrowRight className='text-pink' /></Link>
                                        </div>
                                    }
                                    <Row className=''>
                                        {/* {console.log("PublishedData?.all", PublishedData?.all)} */}
                                        {PublishedData?.all?.map((curr, index) => {

                                            const Audio = curr?.content?.filter((curr) => curr?.media_type === "audio");
                                            const Video = curr?.content?.filter((curr) => curr?.media_type === "video");
                                            const Image = curr?.content?.filter((curr) => curr?.media_type === "image");
                                            const Pdf = curr?.content?.filter((curr) => curr?.media_type === "pdf");
                                            const Doc = curr?.content?.filter((curr) => curr?.media_type === "doc");
                                            const imageCount = Image.length;
                                            const videoCount = Video.length;
                                            const audioCount = Audio.length;
                                            const pdfCount = Pdf.length;
                                            const docCount = Doc.length;
                                            if (index < 4) {
                                                // { console.log("index12312", index, curr) }
                                                return (
                                                    <Col lg={3} md={4} sm={6} key={index}>
                                                        <ContentFeedCard
                                                            feedImg={
                                                                curr.content[0].media_type === "video" ?
                                                                    curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].thumbnail
                                                                    : curr.content[0].media_type === "image" ?
                                                                        curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].media
                                                                        : curr.content[0].media_type === "audio" ?
                                                                            audioic
                                                                            : curr?.content[0]?.media_type === "doc" || 'pdf' ? docsic : ''}

                                                            feedType={curr.content[0].media_type === "video" ? contentVideo : contentCamera}
                                                            feedTag={curr?.mostPopular ? "Popular" : curr?.mostviewed ? "Most viewed" : null}
                                                            userAvatar={imgs}
                                                            authorName={"pseudonymous"}
                                                            lnkto={`/Feeddetail/content/${curr._id}`}
                                                            most_viewed={true}
                                                            author_Name={curr?.hopper_id?.user_name}
                                                            user_avatar={process.env.REACT_APP_AVATAR_IMAGE + curr?.hopper_id?.avatar_id?.avatar}
                                                            fvticns={curr?.favourite_status === "true" ? favouritedic : favic}
                                                            content_id={curr._id}
                                                            bool_fav={curr.favourite_status === "true" ? "false" : "true"}
                                                            favourite={() => handleFavourite()} // Call the function directly
                                                            type_img={curr?.type === "shared" ? shared : exclusive}
                                                            type_tag={curr.type === "shared" ? "Shared" : "Exclusive"}
                                                            feedHead={curr.heading}
                                                            feedTime={moment(curr.published_time_date).format("hh:mm A , DD MMMM YY")}
                                                            feedLocation={curr.location}
                                                            contentPrice={`${formatAmountInMillion(curr.ask_price || 0)}`}

                                                            feedTypeImg1={imageCount > 0 ? cameraic : null}
                                                            postcount={imageCount > 0 ? imageCount : null}
                                                            feedTypeImg2={videoCount > 0 ? videoic : null}
                                                            postcount2={videoCount > 0 ? videoCount : null}
                                                            feedTypeImg3={audioCount > 0 ? interviewic : null}
                                                            postcount3={audioCount > 0 ? audioCount : null}
                                                            feedTypeImg4={pdfCount > 0 ? pdfic : null}
                                                            postcount4={pdfCount > 0 ? pdfCount : null}
                                                            feedTypeImg5={docCount > 0 ? docsic : null}
                                                            postcount5={docCount > 0 ? docCount : null}
                                                        />
                                                    </Col>
                                                );
                                            }
                                            return null; // Return null for items outside of the condition
                                        })}
                                    </Row>

                                </div>
                                <div className="feedsContainer">
                                    {
                                        PublishedData?.exclusive && PublishedData?.exclusive?.length > 0 && <div className="feedContent_header">
                                            <h1 className='rw_hdng'>Exclusive content</h1>
                                            <Link to="/Uploaded-Content/exclusive">View all <BsArrowRight className='text-pink' /></Link>
                                        </div>
                                    }
                                    <Row className=''>
                                        {PublishedData && PublishedData.exclusive?.map((curr, index) => {

                                            const Audio = curr?.content?.filter((curr) => curr?.media_type === "audio")
                                            const Video = curr?.content?.filter((curr) => curr?.media_type === "video")
                                            const Image = curr?.content?.filter((curr) => curr?.media_type === "image")
                                            const Pdf = curr?.content?.filter((curr) => curr?.media_type === "pdf")
                                            const Doc = curr?.content?.filter((curr) => curr?.media_type === "doc")

                                            const imageCount = Image.length;
                                            const videoCount = Video.length;
                                            const audioCount = Audio.length;
                                            const pdfCount = Pdf.length;
                                            const docCount = Doc.length;

                                            if (index < 4) {
                                                return (
                                                    <Col lg={3} md={4} sm={6}>
                                                        <ContentFeedCard
                                                            //  postcount={curr?.content?.length}
                                                            feedImg={
                                                                curr.content[0].media_type === "video" ?
                                                                    curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].thumbnail
                                                                    : curr.content[0].media_type === "image" ?
                                                                        curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].media
                                                                        : curr.content[0].media_type === "audio" ?
                                                                            audioic
                                                                            : ''}
                                                            feedType={curr.content[0].media_type === "video" ? contentVideo : contentCamera} feedTag={"Most Viewed"} userAvatar={imgs} authorName={"pseudonymous"}
                                                            lnkto={`/Feeddetail/content/${curr._id}`}
                                                            fvticns={curr.favourite_status === "true" ? favouritedic : favic}
                                                            content_id={curr._id}
                                                            user_avatar={process.env.REACT_APP_AVATAR_IMAGE + curr?.hopper_id?.avatar_id?.avatar}
                                                            // most_viewed={true}
                                                            author_Name={curr?.hopper_id?.user_name}
                                                            bool_fav={curr.favourite_status === "true" ? "false" : "true"}
                                                            favourite={handleFavourite}
                                                            type_img={curr?.type === "shared" ? shared : exclusive}
                                                            type_tag={curr.type === "shared" ? "Shared" : "Exclusive"}
                                                            feedHead={curr?.heading}
                                                            feedTime={moment(curr.published_time_date).format("hh:mm A , DD MMMM YY")} feedLocation={curr.location} contentPrice={`${formatAmountInMillion(curr.ask_price || 0)}`}
                                                            // feedTypeImg={curr.content[0].media_type === "audio" ? interviewic : cameraic}

                                                            feedTypeImg1={imageCount > 0 ? cameraic : null}
                                                            postcount={imageCount > 0 ? imageCount : null}
                                                            feedTypeImg2={videoCount > 0 ? videoic : null}
                                                            postcount2={videoCount > 0 ? videoCount : null}
                                                            feedTypeImg3={audioCount > 0 ? interviewic : null}
                                                            postcount3={audioCount > 0 ? audioCount : null}
                                                            feedTypeImg4={pdfCount > 0 ? pdfic : null}
                                                            postcount4={pdfCount > 0 ? pdfCount : null}
                                                            feedTypeImg5={docCount > 0 ? docsic : null}
                                                            postcount5={docCount > 0 ? docCount : null}
                                                        />
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </div>
                                <div className="feedsContainer">
                                    {
                                        PublishedData?.shared && PublishedData?.shared?.length > 0 && <div className="feedContent_header">
                                            <h1 className='rw_hdng'>Shared content</h1>
                                            <Link to="/Uploaded-Content/shared">View all <BsArrowRight className='text-pink' /></Link>
                                        </div>
                                    }
                                    <Row className=''>
                                        {PublishedData && PublishedData.shared?.map((curr, index) => {
                                            const Audio = curr?.content?.filter((curr) => curr?.media_type === "audio")
                                            const Video = curr?.content?.filter((curr) => curr?.media_type === "video")
                                            const Image = curr?.content?.filter((curr) => curr?.media_type === "image")
                                            const Pdf = curr?.content?.filter((curr) => curr?.media_type === "pdf")
                                            const Doc = curr?.content?.filter((curr) => curr?.media_type === "doc")
                                            const imageCount = Image.length;
                                            const videoCount = Video.length;
                                            const audioCount = Audio.length;
                                            const pdfCount = Pdf.length;
                                            const docCount = Doc.length;
                                            if (index < 4) {
                                                return (
                                                    <Col lg={3} md={4} sm={6}>
                                                        <ContentFeedCard
                                                            // postcount={curr?.content?.length} 
                                                            feedImg={
                                                                curr.content[0].media_type === "video" ?
                                                                    curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].thumbnail
                                                                    : curr.content[0].media_type === "image" ?
                                                                        curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].media
                                                                        : curr.content[0].media_type === "audio" ?
                                                                            audioic
                                                                            : ''}
                                                            feedType={curr.content[0].media_type === "video" ? contentVideo : contentCamera}
                                                            feedTag={"Most Viewed"} userAvatar={imgs} authorName={"pseudonymous"}
                                                            lnkto={`/Feeddetail/content/${curr._id}`}
                                                            fvticns={curr?.favourite_status === "true" ? favouritedic : favic}
                                                            user_avatar={process.env.REACT_APP_AVATAR_IMAGE + curr?.hopper_id?.avatar_id?.avatar}
                                                            content_id={curr._id}
                                                            most_viewed={true}
                                                            author_Name={curr?.hopper_id?.user_name}
                                                            bool_fav={curr?.favourite_status === "true" ? "false" : "true"}
                                                            favourite={handleFavourite}
                                                            tytype_img={curr?.type === "shared" ? shared : exclusive}
                                                            type_tag={curr.type === "shared" ? "Shared" : "Exclusive"}
                                                            feedHead={curr?.heading}
                                                            feedTime={moment(curr.published_time_date).format("hh:mm A , DD MMMM YY")} feedLocation={curr.location} contentPrice={`${formatAmountInMillion(curr.ask_price || 0)}`}
                                                            // feedTypeImg={curr.content[0].media_type === "audio" ? interviewic : cameraic}
                                                            feedTypeImg1={imageCount > 0 ? cameraic : null}
                                                            postcount={imageCount > 0 ? imageCount : null}
                                                            feedTypeImg2={videoCount > 0 ? videoic : null}
                                                            postcount2={videoCount > 0 ? videoCount : null}
                                                            feedTypeImg3={audioCount > 0 ? interviewic : null}
                                                            postcount3={audioCount > 0 ? audioCount : null}
                                                            feedTypeImg4={pdfCount > 0 ? pdfic : null}
                                                            postcount4={pdfCount > 0 ? pdfCount : null}
                                                            feedTypeImg5={docCount > 0 ? docsic : null}
                                                            postcount5={docCount > 0 ? docCount : null}
                                                        />
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </div>
                                <div className="feedsContainer">
                                    {
                                        PublishedData?.crime && PublishedData?.crime?.length > 0 && <div className="feedContent_header">
                                            <h1 className='rw_hdng'>Crime content</h1>
                                            <Link to="/Uploaded-Content/Crime">View all <BsArrowRight className='text-pink' /></Link>
                                        </div>
                                    }
                                    <Row className=''>
                                        {PublishedData && PublishedData.crime?.map((curr, index) => {
                                            if (index < 4) {
                                                const Audio = curr?.content?.filter((curr) => curr?.media_type === "audio")
                                                const Video = curr?.content?.filter((curr) => curr?.media_type === "video")
                                                const Image = curr?.content?.filter((curr) => curr?.media_type === "image")
                                                const Pdf = curr?.content?.filter((curr) => curr?.media_type === "pdf")
                                                const Doc = curr?.content?.filter((curr) => curr?.media_type === "doc")

                                                const imageCount = Image.length;
                                                const videoCount = Video.length;
                                                const audioCount = Audio.length;
                                                const pdfCount = Pdf.length;
                                                const docCount = Doc.length;
                                                return (
                                                    <Col lg={3} md={4} sm={6}>
                                                        <ContentFeedCard
                                                            //  postcount={curr?.content?.length} 
                                                            feedImg={
                                                                curr.content[0].media_type === "video" ?
                                                                    curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].thumbnail
                                                                    : curr.content[0].media_type === "image" ?
                                                                        curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].media
                                                                        : curr.content[0].media_type === "audio" ?
                                                                            audioic
                                                                            : ''
                                                            }
                                                            feedType={curr.content[0].media_type === "video" ? contentVideo : contentCamera}
                                                            feedTag={"Most Viewed"} userAvatar={imgs} authorName={"pseudonymous"}
                                                            lnkto={`/Feeddetail/content/${curr._id}`}
                                                            user_avatar={process.env.REACT_APP_AVATAR_IMAGE + curr?.hopper_id?.avatar_id?.avatar}

                                                            fvticns={curr.favourite_status === "true" ? favouritedic : favic}
                                                            content_id={curr._id}
                                                            author_Name={curr?.hopper_id?.user_name}
                                                            most_viewed={true}
                                                            bool_fav={curr.favourite_status === "true" ? "false" : "true"}
                                                            favourite={handleFavourite}
                                                            type_img={curr?.type === "shared" ? shared : exclusive}
                                                            type_tag={curr.type === "shared" ? "Shared" : "Exclusive"}
                                                            feedHead={curr?.heading}
                                                            feedTime={moment(curr.published_time_date).format("hh:mm A , DD MMMM YY")} feedLocation={curr.location} contentPrice={`${formatAmountInMillion(curr.ask_price || 0)}`}
                                                            // feedTypeImg={curr.content[0].media_type === "audio" ? interviewic : cameraic}
                                                            feedTypeImg1={imageCount > 0 ? cameraic : null}
                                                            postcount={imageCount > 0 ? imageCount : null}
                                                            feedTypeImg2={videoCount > 0 ? videoic : null}
                                                            postcount2={videoCount > 0 ? videoCount : null}
                                                            feedTypeImg3={audioCount > 0 ? interviewic : null}
                                                            postcount3={audioCount > 0 ? audioCount : null}
                                                            feedTypeImg4={pdfCount > 0 ? pdfic : null}
                                                            postcount4={pdfCount > 0 ? pdfCount : null}
                                                            feedTypeImg5={docCount > 0 ? docsic : null}
                                                            postcount5={docCount > 0 ? docCount : null}
                                                        />
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </div>
                                <div className="feedsContainer mb-0">
                                    {
                                        PublishedData?.celebrity && PublishedData?.celebrity?.length > 0 && <div className="feedContent_header">
                                            <h1 className='rw_hdng'>Celebrity content</h1>
                                            <Link to="/Uploaded-Content/Celebrity">View all <BsArrowRight className='text-pink' /></Link>
                                        </div>
                                    }
                                    <Row className=''>
                                        {PublishedData && PublishedData.celebrity?.map((curr, index) => {

                                            const Audio = curr?.content?.filter((curr) => curr?.media_type === "audio")
                                            const Video = curr?.content?.filter((curr) => curr?.media_type === "video")
                                            const Image = curr?.content?.filter((curr) => curr?.media_type === "image")
                                            const Pdf = curr?.content?.filter((curr) => curr?.media_type === "pdf")
                                            const Doc = curr?.content?.filter((curr) => curr?.media_type === "doc")
                                            const imageCount = Image.length;
                                            const videoCount = Video.length;
                                            const audioCount = Audio.length;
                                            const pdfCount = Pdf.length;
                                            const docCount = Doc.length;

                                            if (index < 4) {
                                                return (
                                                    <Col lg={3} md={4} sm={6}>
                                                        <ContentFeedCard
                                                            //  postcount={curr?.content?.length}
                                                            feedImg={
                                                                curr.content[0].media_type === "video" ?
                                                                    curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].thumbnail
                                                                    : curr.content[0].media_type === "image" ?
                                                                        curr.content[0].watermark || process.env.REACT_APP_CONTENT_MEDIA + curr.content[0].media
                                                                        : curr.content[0].media_type === "audio" ?
                                                                            audioic
                                                                            : ''
                                                            }

                                                            // feedType={curr.content[0].media_type === "video" ? contentVideo : contentCamera}

                                                            feedTag={"Most Viewed"}
                                                            userAvatar={imgs} authorName={"pseudonymous"}
                                                            lnkto={`/Feeddetail/content/${curr._id}`}
                                                            user_avatar={process.env.REACT_APP_AVATAR_IMAGE + curr?.hopper_id?.avatar_id?.avatar}
                                                            fvticns={curr.favourite_status === "true" ? favouritedic : favic}
                                                            content_id={curr._id}
                                                            author_Name={curr?.hopper_id?.user_name}
                                                            most_viewed={true}
                                                            bool_fav={curr.favourite_status === "true" ? "false" : "true"}
                                                            favourite={handleFavourite}
                                                            type_img={curr?.type === "shared" ? shared : exclusive}
                                                            type_tag={curr.type === "shared" ? "Shared" : "Exclusive"}
                                                            feedHead={curr?.heading}
                                                            feedTime={moment(curr.published_time_date).format("hh:mm A , DD MMMM YY")} feedLocation={curr.location} contentPrice={`${formatAmountInMillion(curr.ask_price || 0)}`}
                                                            // feedTypeImg={curr.content[0].media_type === "audio" ? interviewic : cameraic}
                                                            feedTypeImg1={imageCount > 0 ? cameraic : null}
                                                            postcount={imageCount > 0 ? imageCount : null}
                                                            feedTypeImg2={videoCount > 0 ? videoic : null}
                                                            postcount2={videoCount > 0 ? videoCount : null}
                                                            feedTypeImg3={audioCount > 0 ? interviewic : null}
                                                            postcount3={audioCount > 0 ? audioCount : null}
                                                            feedTypeImg4={pdfCount > 0 ? pdfic : null}
                                                            postcount4={pdfCount > 0 ? pdfCount : null}
                                                            feedTypeImg5={docCount > 0 ? docsic : null}
                                                            postcount5={docCount > 0 ? docCount : null}


                                                        />
                                                    </Col>
                                                )
                                            }
                                        })}
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-0">
                        <TopSearchesTipsCard />
                    </div>
                </Container>
            </div >
            <DbFooter />
        </>

    )
}

export default PublishedContent