import React from 'react';

const Loader = () => {
    return (
        // <div className='ldr_ldr'  >
        //    <div className="loader"></div>
        // </div>
        <div className='ldr_ldr'>
            <span className="loader"></span>
        </div>
    );
};

export default Loader;
