import * as React from "react";
import { CardActions, Button, Card, CardContent, Typography } from "@mui/material";
import usr1 from '../assets/images/usrimg11.png';
import { BsArrowRight, BsMic } from "react-icons/bs";
import { IoCallOutline } from "react-icons/io5";
import { VscDeviceCameraVideo } from "react-icons/vsc";
import { MdAdd } from "react-icons/md";
import { InputGroup } from "react-bootstrap";
import inpimg from '../assets/images/profile.webp';
import { Form } from "react-bootstrap";
import presshopchatic from "../assets/images/chat-icons/presshoplogo.svg";
import usr13 from "../assets/images/usrimg13.png";
import usr14 from "../assets/images/ursimg14.png";
// import usr15 from "../assets/images/usrimg15.svg";
// import usr16 from "../assets/images/usrimg16.svg";
import usr17 from "../assets/images/usrimg17.png";
import usr18 from "../assets/images/usrimg18.png";
import { Link } from "react-router-dom";
import { Get, Post } from "../services/user.services";
import ChatCard from "./ChatCard";
import { useState } from "react";
import { useEffect } from "react";
import ChatCardSocket from "./ChatCardSocket";
import { UserDetails } from "./Utils";
import ContentDtlChat from "../pages/ContentDtlChat";
import ContentChatSocket from "./ContentChatSocket";

function ChatParticipants(props) {



    // const CreateRoom = async (id) => {

    // const obj = {
    //     receiver_id: id,
    //     task_id: props.id
    // }

    // const resp = await Post(`mediaHouse/createRoom`, obj)
    //     setRoomDetails(resp.data.details)
    // }

    return (
        <>
            {/* <ContentDtlChat /> */}

            {/* <div className="d-flex gap_20 justify-content-end"> */}
            {/* <ChatCard details={taskDetails} roomDetails={roomDetails} senderId={props.senderId} /> */}
            {/* {taskDetails && <ChatCardSocket details={taskDetails} roomDetails={roomDetails} senderId={props.senderId} />} */}
            {/* <ContentChatSocket /> */}


            {/* </div> */}
        </>

    );
}
export default React.memo(ChatParticipants);
